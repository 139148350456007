import {
  createAdapterToProcess,
  createAdapterToProcessEndpoint,
} from "../../../../parametry/general/process/adapters/process.adapter";
import {
  numericalDateToString,
  stringToNumericalDate,
} from "../../../../utilities/dateUtils";
import {
  MonthClosure,
  MonthClosureEndpoint,
} from "../domain/MonthClosure.model";

type Dictionary = {
  [key: string]: string;
};

const monthClosureAttributesDiccionary: Dictionary = {
  id: "ID",
  dischargeDate: "FechaAlta",
  closureDate: "FechaID",
  closureProcessId: "ProcesoID",
  closureProcess: "Proceso",
};

export const createAdapterToFieldMonthClosureEndpoint = (
  key: string
): string => {
  return monthClosureAttributesDiccionary[key];
};

export const createAdapterToMonthClosureEndpoint = (
  monthClosure: MonthClosure
) => {
  const formattedDate = parseInt(monthClosure.closureDate ? monthClosure.closureDate : '');
  const formattedMonthClosure: MonthClosureEndpoint = {
    ID: monthClosure.id,
    FechaID: formattedDate,
    FechaAlta: monthClosure.dischargeDate,
    ProcesoID: monthClosure.closureProcessId,
    Proceso: monthClosure.closureProcess
      ? createAdapterToProcessEndpoint(monthClosure.closureProcess)
      : undefined,
  };
  return formattedMonthClosure;
};

export const createAdapterToMonthClosure = (
  monthClosureEndpoint: MonthClosureEndpoint
) => {
  const formattedDate = numericalDateToString(monthClosureEndpoint.FechaID ? monthClosureEndpoint.FechaID : 0);
  const formattedMonthClosure: MonthClosure = {
    id: monthClosureEndpoint.ID,
    dischargeDate: monthClosureEndpoint.FechaAlta,
    closureDate: formattedDate,
    closureProcessId: monthClosureEndpoint.ProcesoID,
    closureProcess: monthClosureEndpoint.Proceso
      ? createAdapterToProcess(monthClosureEndpoint.Proceso)
      : undefined,
  };
  return formattedMonthClosure;
};

import { CashFlowDetails, CashFlowDetailsEndpoint, CashFlowMovDaily, CashFlowMovDailyEndpoint, CashFlowTransfer, CashFlowTransferEndpoint } from "../domain/CashFlowDetails.model";

type Dictionary = {
    [key: string]: string;
};

const cashFlowDetailsAttributesDiccionary: Dictionary = {
    id: 'CuentaBancariaID',
    accountNumber: 'CuentaNumero',
    state: 'Estado',
    initialBalance: 'SaldoInicial',
    minimumBalance: 'SaldoMinimo',
    maximumBalance: 'SaldoMaximo',
    totalRevenues: 'TotalIngresos',
    totalExpenses: 'TotalEgresos',
    projectedBalance: 'SaldoProyectado',
    taxDebitsCredits: 'ImpuestoDebitosCreditos',
    dailyTransactions: 'MovimientosDelDia',
    transferredExpenses: 'EgresosTraspasados',
    transferredRevenues: 'IngresosTraspasados',
    subscribeFCI: 'FCISuscribir',
    rescueFCI: 'FCIRescate',
    expenses: 'Egresos',
    revenues: 'Ingresos',
    total: 'Total',
    currencyId: 'MonedaID'
}

export const createAdapterToFieldCashFlowDetailsEndpoint = (key: string): string => {
    return cashFlowDetailsAttributesDiccionary[key];
}

export const createAdapterToCashFlowDetailsEndpoint = (cashFlowDetails: CashFlowDetails) => {
    const formattedCashFlowDetails: CashFlowDetailsEndpoint = {
        CuentaBancariaID: cashFlowDetails.id ?? undefined,
        CuentaNumero: cashFlowDetails.accountNumber,
        Estado: cashFlowDetails.state,
        SaldoInicial: cashFlowDetails.initialBalance,
        SaldoMinimo: cashFlowDetails.minimumBalance,
        SaldoMaximo: cashFlowDetails.maximumBalance,
        TotalIngresos: cashFlowDetails.totalRevenues,
        TotalEgresos: cashFlowDetails.totalExpenses,
        SaldoProyectado: cashFlowDetails.projectedBalance,
        ImpuestoDebitosCreditos: cashFlowDetails.taxDebitsCredits,
        MovimientosDelDia: cashFlowDetails.dailyTransactions,
        EgresosTraspasados: cashFlowDetails.transferredExpenses,
        IngresosTraspasados: cashFlowDetails.transferredRevenues,
        FCISuscribir: cashFlowDetails.subscribeFCI,
        FCIRescate: cashFlowDetails.rescueFCI,
        Egresos: cashFlowDetails.expenses,
        Ingresos: cashFlowDetails.revenues,
        Total: cashFlowDetails.total,
        MonedaID: cashFlowDetails.currencyId
    };
    return formattedCashFlowDetails;
}

export const createAdapterToCashFlowDetails = (cashFlowDetailsEndpoint: CashFlowDetailsEndpoint) => {
    const formattedCashFlowDetails: CashFlowDetails = {
        id: cashFlowDetailsEndpoint.CuentaBancariaID,
        accountNumber: cashFlowDetailsEndpoint.CuentaNumero,
        state: cashFlowDetailsEndpoint.Estado,
        initialBalance: cashFlowDetailsEndpoint.SaldoInicial,
        minimumBalance: cashFlowDetailsEndpoint.SaldoMinimo,
        maximumBalance: cashFlowDetailsEndpoint.SaldoMaximo,
        totalRevenues: cashFlowDetailsEndpoint.TotalIngresos,
        totalExpenses: cashFlowDetailsEndpoint.TotalEgresos,
        projectedBalance: cashFlowDetailsEndpoint.SaldoProyectado,
        taxDebitsCredits: cashFlowDetailsEndpoint.ImpuestoDebitosCreditos,
        dailyTransactions: cashFlowDetailsEndpoint.MovimientosDelDia,
        transferredExpenses: cashFlowDetailsEndpoint.EgresosTraspasados,
        transferredRevenues: cashFlowDetailsEndpoint.IngresosTraspasados,
        subscribeFCI: cashFlowDetailsEndpoint.FCISuscribir,
        rescueFCI: cashFlowDetailsEndpoint.FCIRescate,
        expenses: cashFlowDetailsEndpoint.Egresos,
        revenues: cashFlowDetailsEndpoint.Ingresos,
        total: cashFlowDetailsEndpoint.Total,
        currencyId: cashFlowDetailsEndpoint.MonedaID
    };
    return formattedCashFlowDetails;
}


export const createAdapterToCashFlowMovDailyEndpoint = (cashFlowMovDaily: CashFlowMovDaily) => {
    const formattedCashFlowMovDaily: CashFlowMovDailyEndpoint = {
        CuentaBancariaID: parseInt(cashFlowMovDaily.bankAccountId as unknown as string),
        Monto: parseFloat(cashFlowMovDaily.amount as unknown as string),
        Type: parseInt(cashFlowMovDaily.type as unknown as string),
        BancoID: parseInt(cashFlowMovDaily.bankId as unknown as string)
    };
    return formattedCashFlowMovDaily;
}

export const createAdapterToCashFlowMovDaily = (cashFlowMovDailyEndpoint: CashFlowMovDailyEndpoint) => {
    const formattedCashFlowMovDaily: CashFlowMovDaily = {
        bankAccountId: cashFlowMovDailyEndpoint.CuentaBancariaID,
        amount: cashFlowMovDailyEndpoint.Monto,
        type: cashFlowMovDailyEndpoint.Type,
        bankId: cashFlowMovDailyEndpoint.BancoID
    };
    return formattedCashFlowMovDaily;
}

export const createAdapterToCashFlowTransferEndpoint = (cashFlowTransfer: CashFlowTransfer) => {
    const formattedCashFlowTransfer: CashFlowTransferEndpoint = {
        CuentaFromID: parseInt(cashFlowTransfer.fromBankAccountId as unknown as string),
        Monto: parseFloat(cashFlowTransfer.amount as unknown as string),
        CuentaToID: parseInt(cashFlowTransfer.toBankAccountId as unknown as string),
        BancoID: parseInt(cashFlowTransfer.bankId as unknown as string)
    };
    return formattedCashFlowTransfer;
}

export const createAdapterToCashFlowTransfer = (cashFlowTransferEndpoint: CashFlowTransferEndpoint) => {
    const formattedCashFlowTransfer: CashFlowTransfer = {
        fromBankAccountId: cashFlowTransferEndpoint.CuentaFromID,
        amount: cashFlowTransferEndpoint.Monto,
        toBankAccountId: cashFlowTransferEndpoint.CuentaToID,
        bankId: cashFlowTransferEndpoint.BancoID
    };
    return formattedCashFlowTransfer;
}
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldStatementMovementEndpoint,
  createAdapterToStatementMovement,
  createAdapterToStatementMovementEndpoint,
} from "../adapters/statementMovement.adapter";
import {
  EndpointStatementMovementFiltered,
  StatementMovement,
  StatementMovementEndpoint,
} from "../domain/StatementMovement.model";
import { appConfig } from "../../../../config";
import { EndpointStatementMovement } from "../domain/StatementMovement.model";

import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_STATEMENTMOVEMENT_URL =
  "/entidadesfinancieras/extractosmovimientosbancarios";
const BASE_STATEMENTMOVEMENT_MUTATION_URL =
  "/entidadesfinancieras/extractomovimientobancario";
export const BASE_STATEMENTMOVEMENT_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/extractosmovimientosbancarios/export`;

export const statementMovementApi = createApi({
  reducerPath: "statementMovementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["StatementMovements", "StatementMovement"],
  endpoints: (builder) => ({
    getStatementMovement: builder.query<StatementMovement, number | undefined>({
      query: (id) => `${BASE_STATEMENTMOVEMENT_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointStatementMovement) => {
        return createAdapterToStatementMovement(response.Content);
      },
      providesTags: (_res, _err, id) => [{type: "StatementMovement", id: id?.toString()}],
    }),
    getStatementMovements: builder.query<
      any,
      TableOptions<StatementMovement> | undefined
    >({
      query: (params: TableOptions<StatementMovement>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(
            f,
            createAdapterToFieldStatementMovementEndpoint
          )
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
            field: createAdapterToFieldStatementMovementEndpoint(order.field),
            order: order.order,
          })
          : undefined;
        return {
          url: `${BASE_STATEMENTMOVEMENT_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["StatementMovements"],
      transformResponse: (
        response: EndpointStatementMovementFiltered,
        _meta,
        params: TableOptions<StatementMovement>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (statementMovements: StatementMovementEndpoint) =>
            createAdapterToStatementMovement(statementMovements)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createStatementMovement: builder.mutation<any, StatementMovement>({
      query: (data) => ({
        url: `${BASE_STATEMENTMOVEMENT_MUTATION_URL}`,
        method: "POST",
        body: {
          Content: createAdapterToStatementMovementEndpoint(data),
        },
      }),
      invalidatesTags: ["StatementMovements"],
    }),
    updateStatementMovement: builder.mutation<any, StatementMovement>({
      query: (data: StatementMovement) => ({
        url: `${BASE_STATEMENTMOVEMENT_MUTATION_URL}`,
        method: "PUT",
        body: {
          Content: createAdapterToStatementMovementEndpoint(data),
        },
      }),
      invalidatesTags: (_res, _err, data: StatementMovement) => ["StatementMovements", {type: 'StatementMovement', id: data?.id?.toString()}],
    }),
    deleteStatementMovement: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_STATEMENTMOVEMENT_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["StatementMovements"],
    }),
  }),
});

export const {
  useGetStatementMovementQuery,
  useGetStatementMovementsQuery,
  useCreateStatementMovementMutation,
  useUpdateStatementMovementMutation,
  useDeleteStatementMovementMutation,
} = statementMovementApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToCashFlow,
  createAdapterToCashFlowTemplateEmail,
  createAdapterToCashFlowTemplateEmailEndpoint,
} from "../adapters/cashFlow.adapter";
import {
  CashFlow,
  CashFlowEndpoint,
  CashFlowTemplateEmail,
  EndpointCashFlowTemplateEmail,
} from "../domain/CashFlow.model";
import { appConfig } from "../../../../config";
import { EndpointCashFlowList } from "../domain/CashFlow.model";
import { manageLocalFilters } from "../../../../shared-kernel/components/Table/tableUtils/columnUtils";
import { manageLocalPagination } from "../../../../shared-kernel/components/Table/tableUtils/pagination";

export const BASE_CASH_FLOW_URL = "/estimaciones/cashflow";
const BASE_CASH_FLOW_UPDATE_URL = "/estimaciones/cashflow/update";
const BASE_CASH_FLOW_EMAIL_URL = "/estimaciones/cashflow/template";
const BASE_CASH_FLOW_SEND_EMAIL_URL = "/estimaciones/cashflow/sendemail";
const TAG_CASH_FLOW = "CashFlows";
const TAG_CASH_FLOW_EMAIL = "CashFlowEmail";
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/estimaciones/cashflow/export`;

export const cashFlowApi = createApi({
  reducerPath: "cashFlowApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [TAG_CASH_FLOW, TAG_CASH_FLOW_EMAIL],
  endpoints: (builder) => ({
    getCashFlows: builder.query<any, TableOptions<CashFlow> | undefined>({
      query: () => {
        return `${BASE_CASH_FLOW_URL}`;
      },
      transformResponse: (
        response: EndpointCashFlowList,
        _meta,
        params: TableOptions<CashFlow> | undefined
      ) => {
        let page = params?.page ?? 1;
        let endpointResponse = response.Content.map(
          (cashFlow: CashFlowEndpoint) => createAdapterToCashFlow(cashFlow)
        );
        endpointResponse = manageLocalFilters<CashFlow>(
          endpointResponse,
          params
        );
        return {
          data: manageLocalPagination<CashFlow>(endpointResponse, params),
          page,
          total: endpointResponse.length,
        };
      },
      providesTags: [TAG_CASH_FLOW],
    }),
    getCashFlowTemplateEmail: builder.query<any, undefined>({
      query: () => {
        return `${BASE_CASH_FLOW_EMAIL_URL}`;
      },
      transformResponse: (response: EndpointCashFlowTemplateEmail) => {
        return createAdapterToCashFlowTemplateEmail(response.Content);
      },
      providesTags: [TAG_CASH_FLOW_EMAIL],
    }),
    sendCashFlowTemplateEmail: builder.mutation<any, CashFlowTemplateEmail>({
      query: (data) => ({
        url: `${BASE_CASH_FLOW_SEND_EMAIL_URL}`,
        method: "POST",
        body: {
          Content: createAdapterToCashFlowTemplateEmailEndpoint(data),
        },
      }),
      invalidatesTags: [TAG_CASH_FLOW],
    }),
    initializeCashFlow: builder.mutation<any, undefined>({
      query: () => ({
        url: BASE_CASH_FLOW_URL,
        method: "POST",
      }),
      invalidatesTags: [TAG_CASH_FLOW],
    }),
    updateCashFlow: builder.mutation<any, undefined>({
      query: () => ({
        url: BASE_CASH_FLOW_UPDATE_URL,
        method: "POST",
      }),
      invalidatesTags: [TAG_CASH_FLOW],
    }),
  }),
});

export const {
  useGetCashFlowsQuery,
  useGetCashFlowTemplateEmailQuery,
  useSendCashFlowTemplateEmailMutation,
  useInitializeCashFlowMutation,
  useUpdateCashFlowMutation,
} = cashFlowApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appConfig } from "../../../config";
import { TableOptions } from "../../../shared-kernel/components/Table";
import {
  EndpointLocation,
  EndpointLocationFiltered,
  Location, LocationDelete,
  LocationEndpoint,
  LocationGet,
  LocationInit
} from "../domain/location.model";
import { createAdapterToFieldLocationEndpoint, createAdapterToLocation, createAdapterToLocationEndpoint } from "../adapters/location.adapter";
import { createAdapterToFilterBackend } from "../../../utilities/paramsAdapter";
const BASE_LOCATION_URL = '/estimaciones/locaciones';
const BASE_LOCATION_MUTATION_URL = '/estimaciones/locaciones';
const BASE_LOCATION_MUTATION_INIT_URL = '/estimaciones/locaciones/inicializar';
const TAG_TYPE = 'Locations';
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/estimaciones/locaciones/export`;


export const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [TAG_TYPE, "Location"],
  endpoints: (builder) => ({
    getLocation: builder.query<Location, LocationGet>({
      query: ({ conceptId, date }) => `${BASE_LOCATION_URL}/${conceptId}/${date}`,
      transformResponse: (response: EndpointLocation) => {
        return createAdapterToLocation(response.Content)
      },
      providesTags: (_res, _err, {conceptId, date}) => [{type: "Location", id: `${conceptId}/${date}`}],
    }),
    getLocations: builder.query<any, TableOptions<Location>|undefined>({
      query: (params: TableOptions<Location>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldLocationEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldLocationEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_LOCATION_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: [TAG_TYPE],
      transformResponse: (response: EndpointLocationFiltered, _meta, params: TableOptions<Location>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((location: LocationEndpoint) =>
            createAdapterToLocation(location)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    initLocation: builder.mutation<any, LocationInit>({
      query: (data) => ({
        url: `${BASE_LOCATION_MUTATION_INIT_URL}`,
        method: "POST",
        body: {
          "Content": `${data.year}${data.month}`
        },
      }),
      invalidatesTags: [TAG_TYPE],
    }),
    updateLocation: builder.mutation<any, Location>({
      query: (data) => ({
        url: `${BASE_LOCATION_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToLocationEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: Location) => ["Locations", {type: 'Location', id: `${data?.conceptId}/${data?.date?.replaceAll('-', '')}`}],
    }),
    deleteLocation: builder.mutation<void, LocationDelete>({
      query: ({ conceptId, date }) => ({
        url: `${BASE_LOCATION_MUTATION_URL}/${conceptId}/${date}`,
        method: "DELETE",
      }),
      invalidatesTags: [TAG_TYPE],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useGetLocationQuery,
  useInitLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation
} = locationApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldOwnChequeEndpoint,
  createAdapterToOwnCheque,
  createAdapterToOwnChequeEndpoint,
} from "../adapters/ownCheque.adapter";
import {
  OwnCheque,
  OwnChequeEndpoint,
  EndpointOwnCheque,
  EndpointOwnChequeFiltered,
} from "../domain/OwnCheque.model";
import { appConfig } from "../../../../config";
import { manageLocalFilters } from "../../../../shared-kernel/components/Table/tableUtils/columnUtils";
import { manageLocalPagination } from "../../../../shared-kernel/components/Table/tableUtils/pagination";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_OWNCHEQUE_URL = "/ChequesPropios";
const BASE_OWNPENDINGCHEQUE_URL = "/ChequesPropios/pending";
export const BASE_OWNCHEQUE_EXPORT_URL = `${appConfig.API_NODE_URL}/ChequesPropios/export`;

export const ownChequeApi = createApi({
  reducerPath: "ownChequeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["OwnCheques", "OwnCheque"],
  endpoints: (builder) => ({
    getOwnCheque: builder.query<OwnCheque, number | undefined>({
      query: (id) => `${BASE_OWNCHEQUE_URL}/${id}`,
      transformResponse: (response: OwnChequeEndpoint) => {
        return createAdapterToOwnCheque(response);
      },
      providesTags: (_res, _err, id) => [{type: "OwnCheque", id: id?.toString()}],
    }),
    getOwnCheques: builder.query<any, TableOptions<OwnCheque> | undefined>({
      query: (params: TableOptions<OwnCheque>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) => {
          return createAdapterToFilterBackend(
            f,
            createAdapterToFieldOwnChequeEndpoint
          );
        });
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldOwnChequeEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_OWNCHEQUE_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["OwnCheques"],
      transformResponse: (
        response: EndpointOwnChequeFiltered,
        _meta,
        params: TableOptions<OwnCheque>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (ownCheque: OwnChequeEndpoint) => createAdapterToOwnCheque(ownCheque)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    getOwnPendingCheques: builder.query<
      any,
      TableOptions<OwnCheque> | undefined
    >({
      query: () => {
        return `${BASE_OWNPENDINGCHEQUE_URL}`;
      },
      transformResponse: (
        response: EndpointOwnCheque,
        _meta,
        params: TableOptions<any> | undefined
      ) => {
        let page = params?.page ?? 1;
        let endpointResponse = response.map((c: OwnChequeEndpoint) =>
          createAdapterToOwnCheque(c)
        );
        endpointResponse = manageLocalFilters<any>(endpointResponse, params);
        return {
          data: manageLocalPagination<any>(endpointResponse, params),
          exportData: endpointResponse,
          page,
          total: endpointResponse.length,
        };
      },
    }),
    createOwnCheque: builder.mutation<any, OwnCheque>({
      query: (data: OwnCheque) => ({
        url: `${BASE_OWNCHEQUE_URL}`,
        method: "POST",
        body: createAdapterToOwnChequeEndpoint(data),
      }),
      invalidatesTags: ["OwnCheques"],
    }),
    createOwnChequeList: builder.mutation<any, OwnCheque[]>({
      query: (data) => ({
        url: `${BASE_OWNCHEQUE_URL}/List`,
        method: "POST",
        body: {
          List: data.map((i) => createAdapterToOwnChequeEndpoint(i)),
        },
      }),
      invalidatesTags: ["OwnCheques", "OwnCheque"],
    }),
    updateOwnCheque: builder.mutation<any, OwnCheque>({
      query: (data: OwnCheque) => ({
        url: `${BASE_OWNCHEQUE_URL}/${data.id}`,
        method: "PUT",
        body: createAdapterToOwnChequeEndpoint(data),
      }),
      invalidatesTags: (_res, _err, data: OwnCheque) => ["OwnCheques", {type: 'OwnCheque', id: data?.id?.toString()}],
    }),
    deleteOwnCheque: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_OWNCHEQUE_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OwnCheques"],
    }),
  }),
});

export const {
  useGetOwnChequeQuery,
  useGetOwnChequesQuery,
  useGetOwnPendingChequesQuery,
  useCreateOwnChequeMutation,
  useCreateOwnChequeListMutation,
  useUpdateOwnChequeMutation,
  useDeleteOwnChequeMutation,
} = ownChequeApi;

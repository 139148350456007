import { Toast } from 'primereact/toast';
import { RefObject } from 'react';

declare global {
  interface Window {
    toastRef: RefObject<Toast>;
  }
}

export const appConfig = {
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  API_URL: process.env.REACT_APP_API_NET_URL,
  API_NODE_URL: process.env.REACT_APP_API_NODE_URL,
  APP_TOKEN_NAME: "app_token",
  DEFAULT_LOGIN_DESTINATION: "/dashboard",
  DEFAULT_LOGIN_PAGE: "/login",
  NOT_FOUND_PAGE: "/status/404",
  API_URL_UM: process.env.REACT_APP_API_URL_UM,
  APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
  IMAGENHOMEDEFAULT: process.env.REACT_APP_IMAGENHOMEDEFAULT,
  TITLEHOMEDEFAULT: process.env.REACT_APP_TITLEHOMEDEFAULT,
  HOME_PAGE: "/",
  ROL_ADMIN_NAME: process.env.REACT_APP_ADMIN_NAME,
  HOME_DASHBOARD: process.env.REACT_APP_HOME_DASHBOARD,
  PAYMENTS_MODULE: process.env.REACT_APP_PAYMENTS_MODULE,
  SEARCH_TABLE_DELAY_REQUEST_MS:
    process.env.REACT_APP_SEARCH_TABLE_DELAY_REQUEST_MS,
  ENABLED_ACTIVE_DIRECTORY:
    process.env.REACT_APP_ENABLED_ACTIVE_DIRECTORY === "true" ? true : false,
  DEFAULT_ACTIVE_DIRECTORY:
    process.env.REACT_APP_DEFAULT_ACTIVE_DIRECTORY === "true" ? true : false,
};
import styled from "styled-components";

interface FormProps {
  numColumns?: number;
}

export const Sombra = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
`;

export const Form = styled.form<FormProps>`
  padding: 2rem;
  background-color: #f2f3fa;
  border-radius: 5px;
  position: relative;
  min-width: 45%;
  margin-right: 400px;
  margin: 0 auto;
  .contenedor-grid {
    display: grid;
    grid-template-columns: ${({ numColumns }) =>
      `repeat(${numColumns || 2}, 1fr)`};
    gap: 1rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px
  }
  .contenedor-grid-especial {
    display: grid;
    grid-template-columns: 1fr 5px 1fr;
    gap: 1rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px
  }
  .contenedor-opt {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    position: relative;
    max-width: 320px;
  }
  .error {
    color: #fff;
    background-color: #df2923;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 12px;
    position: absolute;
    bottom: -30px;
    left: 0;
    transition: 0.3s ease-in-out
  }
  .error::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #df2923;
    transition: 0.3s ease-in-out
  }
  .titulo {
    font-size: 18px;
    margin-bottom: 1rem;
    color: #4d4949f8;
  }
  .label {
    font-size: 16px;
    color: #4d4949f8;
    font-weight: 700;
    margin-top: 1.3rem;
    span {
      color: tomato;
    }
  }
  .contenedor-btn {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    width: 100%;
    margin-top: 2.3rem;
    justify-content: flex-end;
  
  }
  .confirmar {
    padding: 7px 18px;
    background-color: #64b33b;
    border: 1px solid #64b33b;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
    font-weight: 600;
    width: 100px;
  }
  .cancelar {
    padding: 7px 18px;
    background-color: #fff;
    border: 1px solid #fff;
    color: #e30613;
    border-radius: 4px;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
    font-weight: 600;
    width: 100px;
  }
  .cerrar {
    color: rgb(255, 255, 255);
    background-color: rgba(77, 73, 73, 0.357);
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 100;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  @media (max-width: 575px) {
    .contenedor-grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 0.5rem;
    }
  }
`;

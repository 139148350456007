import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appConfig } from "../../../../config";
import { EndpointStatementMovementImportList, StatementMovementImport, StatementMovementImportEndpoint } from "../domain/StatementMovementImport.model";
import { createAdapterToStatementMovementEndpoint, createAdapterToStatementMovementImport } from "../adapters/statementMovementImport.adapter";

const BASE_FOREIGN_URL = '/entidadesfinancieras/extractosmovimientosbancarios/upload/foreign'
const BASE_DAILY_URL = '/entidadesfinancieras/extractosmovimientosbancarios/upload/daily'
const BASE_UPLOAD_URL = '/entidadesfinancieras/extractosmovimientosbancarios/upload'
const BASE_IMPORT_URL = '/entidadesfinancieras/extractosmovimientosbancarios/import'

export const statementMovementImportApi = createApi({
  reducerPath: "statementMovementImportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["StatementMovementImports"],
  endpoints: (builder) => ({
    createUploadForeign: builder.mutation<any, any>({
      query: (data) => {
        const formdata = new FormData();
        formdata.append("file", data);
        return {
          url: `${BASE_FOREIGN_URL}`,
          method: "POST",
          body:formdata,
        }
      },
      transformResponse: (response: EndpointStatementMovementImportList) => {
        return response.Content?.Movimientos?.map((r:StatementMovementImportEndpoint) => createAdapterToStatementMovementImport(r))
      },
      invalidatesTags: ["StatementMovementImports"],
    }),
    createUploadDaily: builder.mutation<any, any>({
        query: (data) => {
          const formdata = new FormData();
          formdata.append("file", data);
          return {
            url: `${BASE_DAILY_URL}`,
            method: "POST",
            body:formdata,
          }
        },
        transformResponse: (response: EndpointStatementMovementImportList) => {

          return response.Content?.Movimientos?.map((r:StatementMovementImportEndpoint) => createAdapterToStatementMovementImport(r))
        },
        invalidatesTags: ["StatementMovementImports"],
      }),
      createUploadUpload: builder.mutation<any, any>({
        query: (data) => {
          const formdata = new FormData();
          formdata.append("file", data);
          return {
            url: `${BASE_UPLOAD_URL}`,
            method: "POST",
            body:formdata,
          }
        },
        transformResponse: (response: EndpointStatementMovementImportList) => {
          return {
            Movimientos:response.Content?.Movimientos?.map((r:StatementMovementImportEndpoint) => createAdapterToStatementMovementImport(r)),
            Saldos:response.Content?.Saldos
          }
        },
        invalidatesTags: ["StatementMovementImports"],
      }),
      createUploadImport: builder.mutation<EndpointStatementMovementImportList, any>({
        query: (data) => ({
          url: `${BASE_IMPORT_URL}`,
          method: "POST",
          body: {
            "Content": {
              "Saldos":data.Saldos,
              "Movimientos":data.Movimientos?.map((r:StatementMovementImport) => createAdapterToStatementMovementEndpoint(r))
            }
          },
        }),
        invalidatesTags: ["StatementMovementImports"],
      }),
  }),
});

export const {
  useCreateUploadForeignMutation,
  useCreateUploadDailyMutation,
  useCreateUploadUploadMutation,
  useCreateUploadImportMutation,
} = statementMovementImportApi;

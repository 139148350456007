import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Form, Sombra } from "../../../../styled-components/StyledGlobalForm";
import CloseIcon from "@mui/icons-material/Close";
import { Dropdown } from "primereact/dropdown";
import {
  useCreateMenuMutation,
  useGetMenusQuery,
  useUpdateMenuMutation,
} from "../services/menuApi";
import UploadImage from "../../Inputs/uploadInput/UploadImage";
import { Select } from "../../Inputs";
import SuspenseLoader from "../../SuspenseLoader/SuspenseLoader";
import { toastSweet } from "../../../../utilities/mensajeUtils";
import { Menu } from "../domain/Menu.model";
import { useIntl } from "react-intl";

export const MenuForm = ({
  accionEditar,
  setAccionEditar,
  setOpenForm,
  initialStateMenu,
  menu: parentPropsMenu,
  setMenu: setParentPropsMenu
}: any) => {
  const [
    createMenu,
    {
      isLoading: isLoadingCreateMenu,
      isError: isErrorCreateMenu,
      isSuccess: isSuccessCreateMenu,
      error: errorCreateMenu
    },
  ] = useCreateMenuMutation();
  const [
    updateMenu,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdateMenu
    },
  ] = useUpdateMenuMutation();
  const {
    data: getMenusData,
    isLoading: isLoadingMenus,
    isError: isErrorMenus,
  } = useGetMenusQuery(undefined);
  const [menu, setMenu] = useState({
    name: '',
    order: '0',
    label: '',
    action: '',
    icon: '',
    parentMenu: {
      label: '',
      menuId: '',
      name: ''
    },
    menuId: parentPropsMenu.menuId
  })
  const [errorMessage, setErrorMessage] = useState({
    name: false,
    label: false,
    action: false
  })
  const intl = useIntl();
  const [menuImage, setMenuImage] = useState(menu.icon);
  const handleUpload = (_name: string, file: any) => {
    setMenuImage(file);
  };

  const onChangeForm = (e: { target: { name: any; value: any } }) => {
    let { name, value } = e.target; 
    if(name === 'order' && ((value < 0) || (value == ''))) {
      value = 0
    };
    if(!value) {
      setErrorMessage({ ...errorMessage, [name]: true});
    } else {
      setErrorMessage({ ...errorMessage, [name]: false});
    }
    setMenu({ ...menu, [name]: value });
  };

  const closeForm = () => {
    setMenu(initialStateMenu);
    setAccionEditar(false);
    setOpenForm(false);
  };

  const validateForm = () => {
    const { name, label, action } = menu;
    const newErrors = { ...errorMessage };
    if(!name) {
      newErrors.name = true;
    }
    if(!label) {
      newErrors.label = true;
    }
    if(!action) {
      newErrors.action = true;
    }
    setErrorMessage({...newErrors});
    if(name && label && action) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if(!accionEditar) {
      setMenu((menu: any) => {
        const { menuId, ...rest } = menu;
        return rest;
      })
    }
    if(validateForm()){
      if (accionEditar) {
        optAccion("editar", menu);
      } else {
        optAccion("guardar", menu);
      }
    } else {
      return;
    }
  };

  const optAccion = async (acc: any, data: any) => {
    switch (acc) {
      case "cerrar":
        setOpenForm(false);
        setMenu(initialStateMenu);
        setAccionEditar(false);
        break;
      case "guardar":
        await createMenu({
          ...data,
          icon: menuImage,
        });
        setOpenForm(false);
        setMenu(initialStateMenu);
        setAccionEditar(false);
        break;
      case "editar":
        const updateMenuParams = {
          ...data
        }
        await updateMenu(updateMenuParams);
        setAccionEditar(false);
        setOpenForm(false);
        setMenu(initialStateMenu);
        setParentPropsMenu(initialStateMenu);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if(accionEditar) {
      setMenu({...parentPropsMenu, parentMenu: parentPropsMenu.parentMenu?.menuId})
    }
    if (isSuccessCreateMenu) {
      toastSweet('success', 'Creado satisfactoriamente');
    }
    if (isErrorCreateMenu) {
      toastSweet("error", intl.formatMessage({
        //@ts-ignore
        id: errorCreateMenu?.data?.msg ?? "An error ocurred. Contact the administrator"
      }));
    }
    if (isSuccessUpdate) {
      toastSweet('success', 'Actualizado satisfactoriamente');
    }
    if (isErrorUpdate) {
      toastSweet("error", intl.formatMessage({
        //@ts-ignore
        id: errorUpdateMenu?.data?.msg ?? "An error ocurred. Contact the administrator"
      }));
    }
    if (isErrorMenus) {
      toastSweet('error', 'Error al obtener datos');
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoadingCreateMenu, isLoadingMenus, isLoadingUpdate]);

  if (isLoadingMenus || isLoadingCreateMenu || isLoadingUpdate) {
    return <SuspenseLoader />;
  }

  return (
    <Sombra>
      <Form>
        <h2 className="titulo">{accionEditar ? "ACTUALIZAR" : "AGREGAR"}</h2>
        <div className="contenedor-grid">
          <div className="contenedor-opt">
            <label className="label">Nombre <span>*</span></label>
            <InputText
              required
              value={menu.name || ""}
              placeholder="Nombre"
              name="name"
              onChange={onChangeForm}
            />
            {errorMessage.name && <p className="error">El nombre del cliente es obligatorio.</p>}
          </div>
          <div className="contenedor-opt">
            <label className="label">Alias <span>*</span></label>
            <InputText
              required
              value={menu.label || ""}
              placeholder="Alias"
              name="label"
              onChange={onChangeForm}
            />
            {errorMessage.label && <p className="error">El alias es obligatorio.</p>}
          </div>
          <div className="contenedor-opt">
            <label className="label">Acción <span>*</span></label>
            <InputText
              required
              value={menu.action || ""}
              placeholder="Acción"
              name="action"
              onChange={onChangeForm}
            />
            {errorMessage.action && <p className="error">La acción es obligatoria.</p>}
          </div>
          <div className="contenedor-opt">
            <label className="label">Orden</label>
            <InputText
              required
              value={menu.order}
              placeholder="Orden"
              name="order"
              type="number"
              onChange={onChangeForm}
            />
          </div>
          <div className="contenedor-opt">
            <label className="label">Imagen</label>
            <UploadImage
              id="image-upload"
              name="image"
              onChange={handleUpload}
              file={menuImage}
            />
          </div>
          <div className="contenedor-opt">
            <label className="label">Menú superior</label>
           {!isLoadingMenus && getMenusData ? (
              <Dropdown
                options={getMenusData?.data}
                optionLabel="name"
                optionValue="menuId"
                name="parentMenu"
                value={menu.parentMenu?.menuId || menu.parentMenu}
                placeholder="Seleccione"
                onChange={onChangeForm}
              />
            ) : null}
          </div>
        </div>

        <div className="contenedor-btn">
          <button className="confirmar" onClick={handleSubmit}>
            Confirmar
          </button>
          <button className="cancelar" onClick={closeForm}>
            Cerrar
          </button>
        </div>
        <div className="cerrar">
          <CloseIcon onClick={closeForm} />
        </div>
      </Form>
    </Sombra>
  );
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldThirdPartyChequeEndpoint,
  createAdapterToThirdPartyCheque,
  createAdapterToThirdPartyChequeEndpoint,
} from "../adapters/thirdPartyCheque.adapter";
import {
  ThirdPartyCheque,
  ThirdPartyChequeEndpoint,
  EndpointThirdPartyCheque,
  EndpointThirdPartyChequeFiltered,
} from "../domain/ThirdPartyCheque.model";
import { appConfig } from "../../../../config";
import { manageLocalFilters } from "../../../../shared-kernel/components/Table/tableUtils/columnUtils";
import { manageLocalPagination } from "../../../../shared-kernel/components/Table/tableUtils/pagination";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_THIRDPARTYCHEQUE_URL = "/ChequesTerceros";
const BASE_THIRDPARTYPENDINGCHEQUE_URL = "/ChequesTerceros/pending";
export const BASE_THIRDPARTYPENDINGCHEQUE_EXPORT_URL = `${appConfig.API_NODE_URL}/ChequesTerceros/export`;

export const thirdPartyChequeApi = createApi({
  reducerPath: "thirdPartyChequeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["ThirdPartyCheques", "ThirdPartyCheque"],
  endpoints: (builder) => ({
    getThirdPartyCheque: builder.query<ThirdPartyCheque, number | undefined>({
      query: (id) => `${BASE_THIRDPARTYCHEQUE_URL}/${id}`,
      transformResponse: (response: ThirdPartyChequeEndpoint) => {
        return createAdapterToThirdPartyCheque(response);
      },
      providesTags: (_res, _err, id) => [{type: "ThirdPartyCheque", id: id?.toString()}],
    }),
    getThirdPartyCheques: builder.query<
      any,
      TableOptions<ThirdPartyCheque> | undefined
    >({
      query: (params: TableOptions<ThirdPartyCheque>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(
            f,
            createAdapterToFieldThirdPartyChequeEndpoint
          )
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldThirdPartyChequeEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_THIRDPARTYCHEQUE_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["ThirdPartyCheques"],
      transformResponse: (
        response: EndpointThirdPartyChequeFiltered,
        _meta,
        params: TableOptions<ThirdPartyCheque>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (thirdPartyCheque: ThirdPartyChequeEndpoint) =>
            createAdapterToThirdPartyCheque(thirdPartyCheque)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    getThirdPartyPendingCheques: builder.query<
      any,
      TableOptions<ThirdPartyCheque> | undefined
    >({
      query: () => {
        return `${BASE_THIRDPARTYPENDINGCHEQUE_URL}`;
      },
      transformResponse: (
        response: EndpointThirdPartyCheque,
        _meta,
        params: TableOptions<any> | undefined
      ) => {
        let page = params?.page ?? 1;
        let endpointResponse = response.map((c: ThirdPartyChequeEndpoint) =>
          createAdapterToThirdPartyCheque(c)
        );
        endpointResponse = manageLocalFilters<any>(endpointResponse, params);
        return {
          data: manageLocalPagination<any>(endpointResponse, params),
          exportData: endpointResponse,
          page,
          total: endpointResponse.length,
        };
      },
    }),
    createThirdPartyCheque: builder.mutation<any, ThirdPartyCheque>({
      query: (data) => ({
        url: `${BASE_THIRDPARTYCHEQUE_URL}`,
        method: "POST",
        body: createAdapterToThirdPartyChequeEndpoint(data),
      }),
      invalidatesTags: ["ThirdPartyCheques"],
    }),
    createThirdPartyChequeList: builder.mutation<any, ThirdPartyCheque[]>({
      query: (data) => ({
        url: `${BASE_THIRDPARTYCHEQUE_URL}/List`,
        method: "POST",
        body: {
          List: data.map((i) => createAdapterToThirdPartyChequeEndpoint(i)),
        },
      }),
      invalidatesTags: ["ThirdPartyCheques", "ThirdPartyCheque"],
    }),
    updateThirdPartyCheque: builder.mutation<any, ThirdPartyCheque>({
      query: (data: ThirdPartyCheque) => ({
        url: `${BASE_THIRDPARTYCHEQUE_URL}/${data.id}`,
        method: "PUT",
        body: createAdapterToThirdPartyChequeEndpoint(data),
      }),
      invalidatesTags: (_res, _err, data: ThirdPartyCheque) => ["ThirdPartyCheques", {type: 'ThirdPartyCheque', id: data?.id?.toString()}],
    }),
    deleteThirdPartyCheque: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_THIRDPARTYCHEQUE_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ThirdPartyCheques"],
    }),
  }),
});

export const {
  useGetThirdPartyChequeQuery,
  useGetThirdPartyChequesQuery,
  useGetThirdPartyPendingChequesQuery,
  useCreateThirdPartyChequeMutation,
  useCreateThirdPartyChequeListMutation,
  useUpdateThirdPartyChequeMutation,
  useDeleteThirdPartyChequeMutation,
} = thirdPartyChequeApi;

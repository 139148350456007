import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToConceptType,
} from "../adapters/conceptType.adapter";
import {ConceptType, ConceptTypeEndpoint, EndpointConceptTypeList} from "../domain/ConceptType.model";
import { appConfig } from "../../../../config";

const BASE_CONCEPT_TYPE_URL = '/entidadesfinancieras/tipoconceptos'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/tipoconceptos/export`;

export const conceptTypeApi = createApi({
  reducerPath: "conceptTypeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["conceptTypes"],
  endpoints: (builder) => ({
    getConceptTypes: builder.query<any, TableOptions<ConceptType> | undefined>({
      query: () => {
        return `${BASE_CONCEPT_TYPE_URL}`;
      },
      providesTags: ["conceptTypes"],
      transformResponse: (response: EndpointConceptTypeList | undefined) => {
        const endpointResponse = response?.Content.map((conceptType: ConceptTypeEndpoint) =>
            createAdapterToConceptType(conceptType)
        );
        return {
          data: endpointResponse
        };
      },
    }),
  }),
});

export const {
  useGetConceptTypesQuery,
} = conceptTypeApi;

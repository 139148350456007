import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  EndpointUser, User,
  UserDataProps,
} from "../domain/User.model";
import {createAdapterEndpointUser, createAdapterUser} from "../adapters/user.adapter";
import {TableOptions} from "../../shared-kernel/components/Table";
import {appConfig} from "../../config";

const API_URL_UM = appConfig.API_URL_UM;

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL_UM,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsers: builder.query<UserDataProps, TableOptions<User> | undefined>({
      query: (tableOptions: TableOptions<User>) =>{
        let queryParams: string = '';
        if(tableOptions !== undefined && tableOptions.page){
          const page = tableOptions.page - 1;
          queryParams += `page=${page}`;
        }
        if(tableOptions !== undefined && tableOptions.pageSize){
          queryParams += `&pageSize=${tableOptions.pageSize}`;
        }
        if(tableOptions !== undefined && tableOptions.order){
          const parsedOrder = JSON.stringify({
            field: tableOptions.order.field,
            order: tableOptions.order.order
          })
          queryParams += `&order=${parsedOrder}`;
        }
        if(tableOptions !== undefined && tableOptions.search){
          queryParams += `&search=${tableOptions.search}`;
        }
        return `users${queryParams !== '' ? `?${queryParams}` : ''}`
      }
        ,
      providesTags: ["Users"],
      transformResponse: (response: any) => {
        return {
          data: response.map((u: EndpointUser) => createAdapterUser(u)),
          page: 1,
          total: response.length,
        };
      }
    }),
    updateUser: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `users/${data.id}?applicationId=${appConfig.APPLICATION_ID}`,
        method: "PUT",
        body: createAdapterEndpointUser(data)
      }),
      invalidatesTags: ["Users"],
    }),
    getUser: builder.query<User, string | undefined>({
      query: (userId) => `users/${userId}`,
      providesTags: (_result, _error, userId) => [{ type: 'Users', id: userId }],
      transformResponse: (response: EndpointUser) => createAdapterUser(response),
    }),
    createUser: builder.mutation<any, any>({
      query: (data) => ({
        url: `users?applicationId=${appConfig.APPLICATION_ID}`,
        method: "POST",
        body: createAdapterEndpointUser(data)
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<any, any>({
      query: (id) => ({
        url: `users/${id}?applicationId=${appConfig.APPLICATION_ID}`,
        method: "DELETE",
      }),
      invalidatesTags: ['Users']
    }),
    updatePasswordAdmin: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `users/password/${data.id}`,
        method: "PUT",
        body: {password:data.password}
      }),
      invalidatesTags: ["Users"],
    }),
    updatePasswordUser: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `users/changepassword/${data.id}`,
        method: "PUT",
        body: {password: data.password, repassword: data.repassword, oldPassword: data.oldPassword}
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const { 
  useGetUsersQuery,
  useGetUserQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useUpdatePasswordAdminMutation, 
  useUpdatePasswordUserMutation 
  } = userApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldInvestmentPortfolioEndpoint,
  createAdapterToInvestmentPortfolio,
  createAdapterToInvestmentPortfolioEndpoint
} from "../adapters/investmentPortfolio.adapter";
import { EndpointInvestmentPortfolioFiltered, InvestmentPortfolio, InvestmentPortfolioEndpoint } from "../domain/InvestmentPortfolio.model";
import { appConfig } from "../../../../config";
import { EndpointInvestmentPortfolio } from "../domain/InvestmentPortfolio.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_INVESTMENT_PORTFOLIO_URL = '/entidadesfinancieras/carterasinversiones'
const BASE_INVESTMENT_PORTFOLIO_MUTATION_URL = '/entidadesfinancieras/carterainversion'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/carterasinversiones/export`;

export const investmentPortfolioApi = createApi({
  reducerPath: "investmentPortfolioApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["InvestmentPortfolios", "InvestmentPortfolio"],
  endpoints: (builder) => ({
    getInvestmentPortfolio: builder.query<InvestmentPortfolio, number | undefined>({
      query: (id) => `${BASE_INVESTMENT_PORTFOLIO_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointInvestmentPortfolio) => {
        return createAdapterToInvestmentPortfolio(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "InvestmentPortfolio", id: id?.toString()}],
    }),
    getInvestmentPortfolios: builder.query<any, TableOptions<InvestmentPortfolio>|undefined>({
      query: (params: TableOptions<InvestmentPortfolio>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldInvestmentPortfolioEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldInvestmentPortfolioEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_INVESTMENT_PORTFOLIO_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["InvestmentPortfolios"],
      transformResponse: (response: EndpointInvestmentPortfolioFiltered, _meta, params: TableOptions<InvestmentPortfolio>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((investmentPortfolio: InvestmentPortfolioEndpoint) =>
            createAdapterToInvestmentPortfolio(investmentPortfolio)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createInvestmentPortfolio: builder.mutation<any, InvestmentPortfolio>({
      query: (data) => ({
        url: `${BASE_INVESTMENT_PORTFOLIO_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToInvestmentPortfolioEndpoint(data)
        },
      }),
      invalidatesTags: ["InvestmentPortfolios"],
    }),
    updateInvestmentPortfolio: builder.mutation<any, InvestmentPortfolio>({
      query: (data: InvestmentPortfolio) => ({
        url: `${BASE_INVESTMENT_PORTFOLIO_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToInvestmentPortfolioEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: InvestmentPortfolio) => ["InvestmentPortfolios", {type: 'InvestmentPortfolio', id: data?.id?.toString()}],
    }),
    deleteInvestmentPortfolio: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_INVESTMENT_PORTFOLIO_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["InvestmentPortfolios"],
    }),
  }),
});

export const {
  useGetInvestmentPortfolioQuery,
  useGetInvestmentPortfoliosQuery,
  useCreateInvestmentPortfolioMutation,
  useUpdateInvestmentPortfolioMutation,
  useDeleteInvestmentPortfolioMutation
} = investmentPortfolioApi;

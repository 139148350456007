import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldFinancialInvestmentEndpoint,
  createAdapterToFinancialInvestment,
  createAdapterToFinancialInvestmentEndpoint
} from "../adapters/financialInvestment.adapter";
import { EndpointFinancialInvestmentFiltered, FinancialInvestment, FinancialInvestmentEndpoint } from "../domain/FinancialInvestment.model";
import { appConfig } from "../../../../config";
import { EndpointFinancialInvestment } from "../domain/FinancialInvestment.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_FINANCIALINVESTMENT_URL = '/estimaciones/inversionesfinancieras'
const BASE_FINANCIALINVESTMENT_MUTATION_URL = '/estimaciones/inversionfinanciera'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/estimaciones/inversionesfinancieras/export`; 

export const financialInvestmentApi = createApi({
  reducerPath: "financialInvestmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["FinancialInvestments", "FinancialInvestment"],
  endpoints: (builder) => ({
    getFinancialInvestment: builder.query<FinancialInvestment, number | undefined>({
      query: (id) => `${BASE_FINANCIALINVESTMENT_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointFinancialInvestment) => {
        return createAdapterToFinancialInvestment(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "FinancialInvestment", id: id?.toString()}],
    }),
    getFinancialInvestments: builder.query<any, TableOptions<FinancialInvestment>|undefined>({
      query: (params: TableOptions<FinancialInvestment>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldFinancialInvestmentEndpoint));
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldFinancialInvestmentEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_FINANCIALINVESTMENT_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["FinancialInvestments"],
      transformResponse: (response: EndpointFinancialInvestmentFiltered, _meta, params: TableOptions<FinancialInvestment>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((financialInvestment: FinancialInvestmentEndpoint) =>
            createAdapterToFinancialInvestment(financialInvestment)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createFinancialInvestment: builder.mutation<any, FinancialInvestment[]>({
      query: (data:FinancialInvestment[]) => ({
        url: `${BASE_FINANCIALINVESTMENT_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": data.map((i) => createAdapterToFinancialInvestmentEndpoint(i))
        },
      }),
      invalidatesTags: ["FinancialInvestments"],
    }),
    updateFinancialInvestment: builder.mutation<any, FinancialInvestment>({
      query: (data: FinancialInvestment) => ({
        url: `${BASE_FINANCIALINVESTMENT_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToFinancialInvestmentEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: FinancialInvestment) => ["FinancialInvestments", {type: 'FinancialInvestment', id: data?.id?.toString()}],
    }),
    deleteFinancialInvestment: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_FINANCIALINVESTMENT_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FinancialInvestments"],
    }),
  }),
});

export const {
  useGetFinancialInvestmentQuery,
  useGetFinancialInvestmentsQuery,
  useCreateFinancialInvestmentMutation,
  useUpdateFinancialInvestmentMutation,
  useDeleteFinancialInvestmentMutation
} = financialInvestmentApi;

import styled from "styled-components";

export const SidebarNavLogout = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    svg {
        color: #fff;
    }
    span {
        color: #fff;
        opacity: 1;
        margin: 0 0 0 10px;
        font-size: 16px;
    }
    &:hover {
        background-color: #706f6f;
        svg, span{
            color: #c5c5c5 !important;
        }
    }
`
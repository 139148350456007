import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToExpensePayment,
  createAdapterToExpensePaymentEndpoint,
  createAdapterToFieldExpensePaymentEndpoint,
} from "../adapters/expensePayment.adapter";
import {
  EndpointExpensePaymentFiltered,
  ExpensePayment,
  ExpensePaymentEndpoint,
} from "../domain/ExpensePayment.model";
import { appConfig } from "../../../../config";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_EXPENSEPAYMENT_URL = "/estimaciones/pagos";
const BASE_EXPENSEPAYMENT_BY_ID_URL = "/estimaciones/pagos/egreso";


export const expensePaymentApi = createApi({
  reducerPath: "expensePaymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["ExpensePayments", "OwnCheques", "ThirdPartyCheques"],
  endpoints: (builder) => ({
    getExpensePayment: builder.query<ExpensePayment[], number | undefined>({
      query: (id) => `${BASE_EXPENSEPAYMENT_BY_ID_URL}/${id}`,
      transformResponse: (
        response: ExpensePaymentEndpoint[],
        _meta,
      ) => {
        return response.map((e: ExpensePaymentEndpoint) =>
          createAdapterToExpensePayment(e)
        );
      },
    }),
    getExpensePayments: builder.query<any, TableOptions<ExpensePayment> | undefined>({
      query: (params: TableOptions<ExpensePayment>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(
            f,
            createAdapterToFieldExpensePaymentEndpoint
          )
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldExpensePaymentEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_EXPENSEPAYMENT_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["ExpensePayments"],
      transformResponse: (
        response: EndpointExpensePaymentFiltered,
        _meta,
        params: TableOptions<ExpensePayment>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (customer: ExpensePaymentEndpoint) =>
            createAdapterToExpensePayment(customer)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createExpensePayment: builder.mutation<any, {data:ExpensePayment[], id:number}>({
      query: ({ data, id }) => ({
        url: `${BASE_EXPENSEPAYMENT_URL}/list/${id}`,
        method: "POST",
        body: {pagos: data.map((i) => createAdapterToExpensePaymentEndpoint(i))}
      }),
      invalidatesTags: ["ExpensePayments", "OwnCheques", "ThirdPartyCheques"],
    }),
  }),
});

export const {
  useGetExpensePaymentQuery,
  useGetExpensePaymentsQuery,
  useCreateExpensePaymentMutation,
} = expensePaymentApi;

import styled from "styled-components";

export const UploadImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 20px;
`;

interface AttachmentProps {
    attachWidth?: string;
    attachHeight?: string;
}

export const Attachment = styled.img<AttachmentProps>`
  width: ${props => props.attachWidth ? props.attachWidth : '150px'};
  height: ${props => props.attachHeight ? props.attachHeight : '150px'};
  object-fit: cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

export const StyledLabel = styled.label`
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background: ${(props) => props.theme.inputs.select.background};
`;

export const CancelButton = styled.button`
  border: none;
  background: none;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  cursor: pointer;
  background: ${(props) => props.theme.inputs.select.background};
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
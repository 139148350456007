import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToStatementBalance,
  createAdapterToStatementBalanceEndpoint
} from "../adapters/statementBalance.adapter";
import { StatementBalance, StatementBalanceEndpoint } from "../domain/StatementBalance.model";
import { appConfig } from "../../../../config";
import { EndpointStatementBalance, EndpointStatementBalanceList } from "../domain/StatementBalance.model";
import {manageLocalFilters} from "../../../../shared-kernel/components/Table/tableUtils/columnUtils";
import {manageLocalPagination} from "../../../../shared-kernel/components/Table/tableUtils/pagination";

const BASE_STATEMENTBALANCE_URL = '/entidadesfinancieras/extractossaldosbancarios'
const BASE_STATEMENTBALANCE_MUTATION_URL = '/entidadesfinancieras/extractosaldobancario'

export const statementBalanceApi = createApi({
  reducerPath: "statementBalanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_URL,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["StatementBalances", "StatementBalance"],
  endpoints: (builder) => ({
    getStatementBalance: builder.query<StatementBalance, number | undefined>({
      query: (id) => `${BASE_STATEMENTBALANCE_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointStatementBalance) => {
        return createAdapterToStatementBalance(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "StatementBalance", id: id?.toString()}],
    }),
    getStatementBalances: builder.query<any, TableOptions<StatementBalance> | undefined>({
      query: () => {
        return `${BASE_STATEMENTBALANCE_URL}`;
      },
      providesTags: ["StatementBalances"],
      transformResponse: (response: EndpointStatementBalanceList, _meta, params: TableOptions<StatementBalance> | undefined) => {
        let page = params?.page ?? 1;
        let endpointResponse = response.Content.map((statementBalance: StatementBalanceEndpoint) =>
            createAdapterToStatementBalance(statementBalance)
        );
        endpointResponse = manageLocalFilters<StatementBalance>(endpointResponse, params);
        return {
          data: manageLocalPagination<StatementBalance>(endpointResponse, params),
          page,
          total: endpointResponse.length,
        };
      },
    }),
    createStatementBalance: builder.mutation<any, StatementBalance>({
      query: (data) => ({
        url: `${BASE_STATEMENTBALANCE_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToStatementBalanceEndpoint(data)
        },
      }),
      invalidatesTags: ["StatementBalances"],
    }),
    updateStatementBalance: builder.mutation<any, StatementBalance>({
      query: (data: StatementBalance) => ({
        url: `${BASE_STATEMENTBALANCE_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToStatementBalanceEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: StatementBalance) => ["StatementBalances", {type: 'StatementBalance', id: data?.id?.toString()}],
    }),
  }),
});

export const {
  useGetStatementBalanceQuery,
  useGetStatementBalancesQuery,
  useCreateStatementBalanceMutation,
  useUpdateStatementBalanceMutation,
} = statementBalanceApi;

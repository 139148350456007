import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import withReactContent from "sweetalert2-react-content";
import translate from "../language/translate";

const MySwal = withReactContent(Swal);

export const mostrarMensaje = (icon: any, title: any, text: any) => {
  Swal.fire({
    icon: icon,
    title: title,
    text: text,
    confirmButtonColor: "#64B33B",
    customClass: {
      popup: "my-swal",
    },
  });
};


const Close = () => {
  const cerrarModal = () => {
    Swal.close();
  };

  return (
    <div className="btn-cerrar-sweet" onClick={cerrarModal}>
       <CloseIcon />
    </div>
  );
};

export const confirmarMensaje = async (
  title: string,
  text: string,
  confirmText: string,
  icon?: "success" | "error" | "info" | "warning",
  mostrarCancel: boolean = true
) => {
  const result = await MySwal.fire({
    title: (
      <div style={{ display: 'flex',flexDirection: 'column' , alignItems: 'center', justifyContent: 'center' }}>
        <Close/>
        <span style={{ marginLeft: '10px' }}>{title}</span>
      </div>
    ),
    html: text,
    icon: icon,
    showCancelButton: mostrarCancel,
    confirmButtonColor: "tomato",
    confirmButtonText: confirmText,
    cancelButtonText: "Cancelar",
    customClass: {
      popup: "custom-swal-background",
      actions: "swal2-actions-right",
    },
    buttonsStyling: false,
  });

  return result.isConfirmed;
};

let toastQueue: any[] = [];
let toastInProgress = false;

const defineSummary = (title: string) => {
  switch (title) {
    case "success":
      return 'Éxito'
    case "error":
      return 'Error'
    case "info":
      return 'Información'
    case "warn":
      return 'Atención'
    case "secondary":
      return 'Secundario'
    case "contrast":
      return 'Contraste'
  
    default:
      break;
  }
}

export const toastSweet = (icon: "success" | "error" | "info" | "warn" | "secondary" | "contrast", text: string, timer: number = 7000) => {
  window.toastRef.current?.show({
    severity: icon,
    summary: defineSummary(icon),
    detail: text,
    life: timer,
  });
};

function showNextToast() {
  if (toastQueue.length === 0) {
    toastInProgress = false;
    return;
  }

  toastInProgress = true;
  const { icon, text, timer } = toastQueue.shift();

  Swal.fire({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer,
    width: "35%",
    timerProgressBar: true,
    icon: icon,
    title: text,
    showCloseButton: true,
    // didOpen: (toast) => {
    //   toast.onmouseenter = Swal.stopTimer;
    //   toast.onmouseleave = Swal.resumeTimer;
    // },
  }).then(() => {
    toastInProgress = false;
    showNextToast();
  });
}
import React from "react";
import axios from "axios";
import {
  Actions,
  Attachment,
  CancelButton,
  StyledLabel,
  UploadImageContainer
} from "./styled-components/StyledUploadImage";
import translate from "../../../../language/translate";
import {appConfig} from "../../../../config";

interface UploadImageprops {
  id: string;
  name: string;
  file: any;
  fileType?: string;
  attachWidth?: string;
  attachHeight?: string;
  onChange: any;
  required?: boolean;
}

const UploadImage = ({ id, name, file, attachWidth, attachHeight, onChange, required, fileType }: UploadImageprops) => {
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      let formdata = new FormData();
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);
      formdata.append("attachments", file);
      const url = `${appConfig.API_NODE_URL}/upload`;
      axios
        .post(url, formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "authorization": `${token}`
          },
        })
        .then((response) => {
          onChange(name, response.data.url);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleReset = (e: any) => {
    e.preventDefault();
    onChange(name, null);
  };

  return (
    <UploadImageContainer>
      <input
        id={id ?? "contained-upload-file"}
        name={name}
        type="file"
        accept={fileType ?? "image/*"}
        style={{ display: "none" }}
        onChange={handleChange}
        required={required}
      />
      {file ? (
        <Attachment attachWidth={attachWidth} attachHeight={attachHeight} src={file} alt="principal image" />
      ) : (
        <span>{translate("file not selected")}{required ? '*' : null}</span>
      )}
      <Actions>
        <StyledLabel htmlFor={id ?? "contained-upload-file"}>
          {file ? translate("change") : translate("choose file")}
        </StyledLabel>
        {file ? (
          <CancelButton onClick={handleReset}>
            {translate("remove")}
          </CancelButton>
        ) : null}
      </Actions>
    </UploadImageContainer>
  );
};

export default UploadImage;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToChequeState,
  createAdapterToChequeStateEndpoint,
  createAdapterToFieldChequeStateEndpoint,
} from "../adapters/chequeState.adapter";
import {
  ChequeState,
  ChequeStateEndpoint,
  EndpointChequeStateFiltered,
} from "../domain/ChequeState.model";
import { appConfig } from "../../../../config";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_CHEQUESTATE_URL = "/Estados";
const BASE_CHEQUESTATE_MUTATION_URL = "/Estados";
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/Estados/export`;

export const chequeStateApi = createApi({
  reducerPath: "chequeStateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["ChequeStates", "ChequeState"],
  endpoints: (builder) => ({
    getChequeState: builder.query<ChequeState, number | undefined>({
      query: (id) => `${BASE_CHEQUESTATE_MUTATION_URL}/${id}`,
      transformResponse: (response: ChequeStateEndpoint) => {
        return createAdapterToChequeState(response);
      },
      providesTags: (_res, _err, id) => [{type: "ChequeState", id: id?.toString()}],
    }),
    getChequeStates: builder.query<any, TableOptions<ChequeState> | undefined>({
      query: (params: TableOptions<ChequeState>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(
            f,
            createAdapterToFieldChequeStateEndpoint
          )
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldChequeStateEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_CHEQUESTATE_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["ChequeStates"],
      transformResponse: (
        response: EndpointChequeStateFiltered,
        _meta,
        params: TableOptions<ChequeState>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (chequeState: ChequeStateEndpoint) =>
            createAdapterToChequeState(chequeState)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createChequeState: builder.mutation<any, ChequeState>({
      query: (data) => ({
        url: `${BASE_CHEQUESTATE_MUTATION_URL}`,
        method: "POST",
        body: createAdapterToChequeStateEndpoint(data),
      }),
      invalidatesTags: ["ChequeStates"],
    }),
    updateChequeState: builder.mutation<any, ChequeState>({
      query: (data: ChequeState) => ({
        url: `${BASE_CHEQUESTATE_MUTATION_URL}/${data.id}`,
        method: "PUT",
        body: createAdapterToChequeStateEndpoint(data),
      }),
      invalidatesTags: (_res, _err, data: ChequeState) => ["ChequeStates", {type: 'ChequeState', id: data?.id?.toString()}],
    }),
    deleteChequeState: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_CHEQUESTATE_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ChequeStates"],
    }),
  }),
});

export const {
  useGetChequeStateQuery,
  useGetChequeStatesQuery,
  useCreateChequeStateMutation,
  useUpdateChequeStateMutation,
  useDeleteChequeStateMutation,
} = chequeStateApi;

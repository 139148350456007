import {
  CashFlow,
  CashFlowEndpoint,
  CashFlowTemplateEmail,
  CashFlowTemplateEmailEndpoint,
} from "../domain/CashFlow.model";

type Dictionary = {
  [key: string]: string;
};

const cashFlowAttributesDiccionary: Dictionary = {
  id: "BancoID",
  bank: "Banco",
  initialBalance: "SaldoInicial",
  minimumBalance: "SaldoMinimo",
  maximumBalance: "SaldoMaximo",
  totalRevenues: "TotalIngresos",
  totalExpenses: "TotalEgresos",
  projectedBalance: "SaldoProyectado",
  dailyTransactions: "MovimientosDiarios",
  accountsTransactions: "Transferencias",
  state: "Estado",
};

export const createAdapterToFieldCashFlowEndpoint = (key: string): string => {
  return cashFlowAttributesDiccionary[key];
};

export const createAdapterToCashFlowEndpoint = (cashFlow: CashFlow) => {
  const formattedCashFlow: CashFlowEndpoint = {
    BancoID: cashFlow.id ?? undefined,
    Banco: cashFlow.bank,
    SaldoInicial: cashFlow.initialBalance,
    SaldoMinimo: cashFlow.minimumBalance,
    SaldoMaximo: cashFlow.maximumBalance,
    TotalIngresos: cashFlow.totalRevenues,
    TotalEgresos: cashFlow.totalExpenses,
    SaldoProyectado: cashFlow.projectedBalance,
    MovimientosDiarios: cashFlow.dailyTransactions,
    Transferencias: cashFlow.accountsTransactions,
    Estado: cashFlow.state,
  };
  return formattedCashFlow;
};

export const createAdapterToCashFlow = (cashFlowEndpoint: CashFlowEndpoint) => {
  const formattedCashFlow: CashFlow = {
    id: cashFlowEndpoint.BancoID,
    bank: cashFlowEndpoint.Banco,
    initialBalance: cashFlowEndpoint.SaldoInicial,
    minimumBalance: cashFlowEndpoint.SaldoMinimo,
    maximumBalance: cashFlowEndpoint.SaldoMaximo,
    totalRevenues: cashFlowEndpoint.TotalIngresos,
    totalExpenses: cashFlowEndpoint.TotalEgresos,
    projectedBalance: cashFlowEndpoint.SaldoProyectado,
    dailyTransactions: cashFlowEndpoint.MovimientosDiarios,
    accountsTransactions: cashFlowEndpoint.Transferencias,
    state: cashFlowEndpoint.Estado,
  };
  return formattedCashFlow;
};

export const createAdapterToExportCashFlow = (
  cashFlowEndpoint: CashFlowEndpoint
) => {
  const REGULARIZAR_ID = 2;
  const REGULARIZAR_NAME = "Regularizar";
  const NO_REGULARIZAR_NAME = "Excedente";
  const formattedCashFlow: any = {
    id: cashFlowEndpoint.BancoID,
    bank: cashFlowEndpoint.Banco,
    initialBalance: cashFlowEndpoint.SaldoInicial,
    minimumBalance: cashFlowEndpoint.SaldoMinimo,
    maximumBalance: cashFlowEndpoint.SaldoMaximo,
    totalRevenues: cashFlowEndpoint.TotalIngresos,
    totalExpenses: cashFlowEndpoint.TotalEgresos,
    projectedBalance: cashFlowEndpoint.SaldoProyectado,
    dailyTransactions: cashFlowEndpoint.MovimientosDiarios,
    accountsTransactions: cashFlowEndpoint.Transferencias,
    state:
      cashFlowEndpoint.Estado === REGULARIZAR_ID
        ? REGULARIZAR_NAME
        : NO_REGULARIZAR_NAME,
  };
  return formattedCashFlow;
};

export const createAdapterToCashFlowTemplateEmailEndpoint = (
  cashFlowTemplateEmail: CashFlowTemplateEmail
) => {
  const formattedCashFlow: CashFlowTemplateEmailEndpoint = {
    TemplateID: cashFlowTemplateEmail.id ?? undefined,
    Descripcion: cashFlowTemplateEmail.description,
    Remitente: cashFlowTemplateEmail.sender,
  };
  return formattedCashFlow;
};

export const createAdapterToCashFlowTemplateEmail = (
  cashFlowEndpoint: CashFlowTemplateEmailEndpoint
) => {
  const formattedCashFlowTemplateEmail: CashFlowTemplateEmail = {
    id: cashFlowEndpoint.TemplateID,
    description: cashFlowEndpoint.Descripcion,
    sender: cashFlowEndpoint.Remitente,
  };
  return formattedCashFlowTemplateEmail;
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldInvestmentInstrumentEndpoint,
  createAdapterToInvestmentInstrument,
  createAdapterToInvestmentInstrumentEndpoint
} from "../adapters/investmentInstrument.adapter";
import { EndpointInvestmentInstrumentFiltered, InvestmentInstrument, InvestmentInstrumentEndpoint } from "../domain/InvestmentInstrument.model";
import { appConfig } from "../../../../config";
import { EndpointInvestmentInstrument } from "../domain/InvestmentInstrument.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_INVESTMENTINSTRUMENT_URL = '/entidadesfinancieras/instrumentosinversiones'
const BASE_INVESTMENTINSTRUMENT_MUTATION_URL = '/entidadesfinancieras/instrumentoinversion'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/instrumentosinversiones/export`;

export const investmentInstrumentApi = createApi({
  reducerPath: "investmentInstrumentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["InvestmentInstruments", "InvestmentInstrument"],
  endpoints: (builder) => ({
    getInvestmentInstrument: builder.query<InvestmentInstrument, number | undefined>({
      query: (id) => `${BASE_INVESTMENTINSTRUMENT_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointInvestmentInstrument) => {
        return createAdapterToInvestmentInstrument(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "InvestmentInstrument", id: id?.toString()}],
    }),
    getInvestmentInstruments: builder.query<any, TableOptions<InvestmentInstrument>|undefined>({
      query: (params: TableOptions<InvestmentInstrument>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldInvestmentInstrumentEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldInvestmentInstrumentEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_INVESTMENTINSTRUMENT_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["InvestmentInstruments"],
      transformResponse: (response: EndpointInvestmentInstrumentFiltered, _meta, params: TableOptions<InvestmentInstrument>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((investmentInstrument: InvestmentInstrumentEndpoint) =>
            createAdapterToInvestmentInstrument(investmentInstrument)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createInvestmentInstrument: builder.mutation<any, InvestmentInstrument>({
      query: (data) => ({
        url: `${BASE_INVESTMENTINSTRUMENT_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToInvestmentInstrumentEndpoint(data)
        },
      }),
      invalidatesTags: ["InvestmentInstruments"],
    }),
    updateInvestmentInstrument: builder.mutation<any, InvestmentInstrument>({
      query: (data: InvestmentInstrument) => ({
        url: `${BASE_INVESTMENTINSTRUMENT_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToInvestmentInstrumentEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: InvestmentInstrument) => ["InvestmentInstruments", {type: 'InvestmentInstrument', id: data?.id?.toString()}],
    }),
    deleteInvestmentInstrument: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_INVESTMENTINSTRUMENT_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["InvestmentInstruments"],
    }),
  }),
});

export const {
  useGetInvestmentInstrumentQuery,
  useGetInvestmentInstrumentsQuery,
  useCreateInvestmentInstrumentMutation,
  useUpdateInvestmentInstrumentMutation,
  useDeleteInvestmentInstrumentMutation
} = investmentInstrumentApi;

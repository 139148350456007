import styled from "styled-components";

interface SelectLayoutProps {
  hidden?: boolean;
  width?: string;
}

export const SelectLayout = styled.div<SelectLayoutProps>`
  position: relative;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  margin: 8px 0;
  width: ${props => props.width ? props.width : 'auto'};
  z-index: 0;
  label{
    padding: 15px;
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  outline: none;
  font-size: 1em;
  color: gray;
  background: ${(props) => props.theme.inputs.select.background};
  padding: 8.5px 14px;
  overflow: auto;
  border: ${(props) => `1px solid ${props.theme.secondary.light}`};
  border-radius: 4px;
  &:hover {
    box-shadow: ${(props) => `0 0 5px ${props.theme.secondary.light}`};
  }
  svg {
    color: gray;
  }
`;

export const StyledOption = styled.option`
  &:checked {
    background: ${(props) => props.theme.inputs.select.selectedBackground};
    color: ${(props) => props.theme.inputs.select.selectedColor};
  }
  &:not(:checked) {
    background: ${(props) => props.theme.inputs.select.background};
    color: ${(props) => props.theme.inputs.select.color};
  }
`;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createAdapterToCashFlowDetails, createAdapterToCashFlowMovDailyEndpoint, createAdapterToCashFlowTransferEndpoint,
} from "../adapters/cashFlowDetails.adapter";
import { CashFlowDetails, CashFlowDetailsEndpoint, EndpointCashFlowDetailsList } from "../domain/CashFlowDetails.model";
import { appConfig } from "../../../../config";
import { manageLocalPagination } from "../../../../shared-kernel/components/Table/tableUtils/pagination";
import { TableOptions } from "../../../../shared-kernel/components/Table";

const BASE_CASH_FLOW_DETAILS_URL = '/estimaciones/cashflow'
const TAG_TYPE = 'CashFlowDetails';
const ADDMOVDAY_URL = 'estimaciones/cashflow/addmovday'
const ADDTRANSFER_URL = 'estimaciones/cashflow/addtrasfer'

export const cashFlowDetailsApi = createApi({
  reducerPath: "cashFlowDetailsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [TAG_TYPE],
  endpoints: (builder) => ({
    getCashFlowDetails: builder.query<any, {id:string | undefined, params:TableOptions<CashFlowDetails>| undefined} >({
      query: ({id}) => `${BASE_CASH_FLOW_DETAILS_URL}/${id}`,
      providesTags: [TAG_TYPE],
      transformResponse: (response: EndpointCashFlowDetailsList,  _meta, params: {id:string | undefined, params:TableOptions<CashFlowDetails>| undefined}) => {
        let page = params?.params?.page ?? 1;
        const endpointResponse = response.Content.map((cashFlowDetails: CashFlowDetailsEndpoint) =>
        createAdapterToCashFlowDetails(cashFlowDetails));
        return {
          data: manageLocalPagination<CashFlowDetails>(endpointResponse, params.params),
          page: page,
          total: response.Content.length,
        };
      },
    }),
    updateCFDailyMovement: builder.mutation<any, any>({
      query: (data) => ({
        url: `${ADDMOVDAY_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToCashFlowMovDailyEndpoint(data)
        },
      }),
      invalidatesTags: [TAG_TYPE],
    }),
    updateCFTransfer: builder.mutation<any, any>({
      query: (data) => ({
        url: `${ADDTRANSFER_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToCashFlowTransferEndpoint(data)
        },
      }),
      invalidatesTags: [TAG_TYPE],
    }),
  }),
});

export const {
  useGetCashFlowDetailsQuery,
  useUpdateCFTransferMutation,
  useUpdateCFDailyMovementMutation,
} = cashFlowDetailsApi;

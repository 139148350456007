import { createAdapterToState } from "../../../../parametry/estimates/state/adapters/state.adapter";
import { createAdapterToBankAccount } from "../../../../parametry/financial/bankAccount/adapters/bankAccount.adapter";
import { createAdapterToConcept } from "../../../../parametry/financial/concept/adapters/concept.adapter";
import { createAdapterToArea } from "../../../../parametry/general/area/adapters/area.adapter";
import { createAdapterToCurrency } from "../../../../parametry/general/currency/adapters/currency.adapter";
import {
  numericalDateToString,
  stringToNumericalDate,
} from "../../../../utilities/dateUtils";
import {
  Customer_wPersona,
  Customer_wPersonaEndpoint,
  Income,
  IncomeEndpoint,
} from "../domain/Income.model";

type Dictionary = {
  [key: string]: string;
};

const incomeAttributesDiccionary: Dictionary = {
  id: "IngresoID",
  firstEstDate: "FechaPrimeraEstimacionID",
  lastEstDate: "FechaUltimaEstimacionID",
  conceptId: "ConceptoID",
  areaId: "AreaID",
  currencyId: "MonedaID",
  bankAccountId: "CuentaBancariaID",
  currencyLocationId: "MonedaLocacionID",
  currencyOpId: "MonedaOperacionID",
  exchangeRateOp: "TipoCambioOperacion",
  stateId: "EstadoID",
  customerId: "ClienteID",
  comment: "Comentario",
  firstEstAmount: "ImportePrimeraEstimacion",
  lastEstAmount: "ImporteUltimaEstimacion",
};

export const createAdapterToFieldIncomeEndpoint = (key: string): string => {
  return incomeAttributesDiccionary[key];
};

export const createAdapterToIncomeEndpoint = (income: Income) => {
  const formattedIncome: Partial<IncomeEndpoint> = {
    IngresoID: income.id ?? undefined,
    FechaPrimeraEstimacionID: income.firstEstDate
      ? stringToNumericalDate(income.firstEstDate)
      : undefined,
    FechaUltimaEstimacionID: income.lastEstDate
      ? stringToNumericalDate(income.lastEstDate)
      : undefined,
    ConceptoID: income.conceptId,
    AreaID: income.areaId,
    CuentaBancariaID: income.bankAccountId,
    MonedaLocacionID: income.currencyLocationId,
    MonedaOperacionID: income.currencyOpId,
    TipoCambioOperacion: income.exchangeRateOp,
    EstadoID: income.stateId,
    ClienteID: income.customerId,
    Comentario: income.comment,
    ImportePrimeraEstimacion: income.firstEstAmount,
    ImporteUltimaEstimacion:
      income.lastEstAmount !== undefined && !isNaN(income.lastEstAmount)
        ? income.lastEstAmount
        : undefined,
  };
  return formattedIncome;
};

export const createAdapterToIncome = (incomeEndpoint: IncomeEndpoint) => {

  const formattedIncome: Income = {
    id: incomeEndpoint.IngresoID,
    firstEstDate: incomeEndpoint.FechaPrimeraEstimacionID
      ? numericalDateToString(incomeEndpoint.FechaPrimeraEstimacionID)
      : undefined,
    lastEstDate: incomeEndpoint.FechaUltimaEstimacionID
      ? numericalDateToString(incomeEndpoint.FechaUltimaEstimacionID)
      : undefined,
    conceptId: incomeEndpoint.ConceptoID,
    concept: incomeEndpoint.Concepto
      ? createAdapterToConcept(incomeEndpoint.Concepto)
      : undefined,
    areaId: incomeEndpoint.AreaID,
    area: incomeEndpoint.Area
      ? createAdapterToArea(incomeEndpoint.Area)
      : undefined,
    bankAccountId: incomeEndpoint.CuentaBancariaID,
    bankAccount: incomeEndpoint.CuentaBancaria
      ? createAdapterToBankAccount(incomeEndpoint.CuentaBancaria)
      : undefined,
    currencyLocationId: incomeEndpoint.MonedaLocacionID,
    currencyLocation: incomeEndpoint.MonedaLocacion
      ? createAdapterToCurrency(incomeEndpoint.MonedaLocacion)
      : undefined,
    currencyOpId: incomeEndpoint.MonedaOperacionID,
    currencyOp: incomeEndpoint.MonedaOperacion
      ? createAdapterToCurrency(incomeEndpoint.MonedaOperacion)
      : undefined,
    exchangeRateOp: incomeEndpoint.TipoCambioOperacion,
    stateId: incomeEndpoint.EstadoID,
    state: incomeEndpoint.Estado
      ? createAdapterToState(incomeEndpoint.Estado)
      : undefined,
    customerId: incomeEndpoint.ClienteID,
    customer: incomeEndpoint.Cliente
      ? createAdapterToCustomer_wPersona(incomeEndpoint.Cliente)
      : undefined,
    comment: incomeEndpoint.Comentario,
    firstEstAmount: incomeEndpoint.ImportePrimeraEstimacion,
    lastEstAmount: incomeEndpoint.ImporteUltimaEstimacion,
  };
  return formattedIncome;
};

const createAdapterToCustomer_wPersona = (
  customer_wPersonaEndpoint: Customer_wPersonaEndpoint
) => {
  const formattedcustomer_wPersona: Customer_wPersona = {
    personId: customer_wPersonaEndpoint.PersonaID,
    person: customer_wPersonaEndpoint.Persona,
    CUIT: customer_wPersonaEndpoint.CUIT,
  };
  return formattedcustomer_wPersona;
};

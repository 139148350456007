import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToHeadquarters,
  createAdapterToHeadquartersEndpoint
} from "../adapters/headquarters.adapter";
import { Headquarters, HeadquartersEndpoint } from "../domain/Headquarters.model";
import { appConfig } from "../../../../config";
import { EndpointHeadquarters } from "../domain/Headquarters.model";
import {manageLocalFilters} from "../../../../shared-kernel/components/Table/tableUtils/columnUtils";
import {manageLocalPagination} from "../../../../shared-kernel/components/Table/tableUtils/pagination";

const BASE_HEADQUARTERS_URL = '/FactHamblinWatsaHandler'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/FactHamblinWatsaHandler/export`;

export const headquartersApi = createApi({
  reducerPath: "headquartersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["Headquarterss", "Headquarters"],
  endpoints: (builder) => ({
    getHeadquarters: builder.query<Headquarters, string | undefined>({
      query: (id) => `${BASE_HEADQUARTERS_URL}/${id}`,
      transformResponse: (response: HeadquartersEndpoint) => {
        return createAdapterToHeadquarters(response)
      },
      providesTags: (_res, _err, id) => [{type: "Headquarters", id: id?.toString()}],
    }),
    getHeadquarterss: builder.query<any, TableOptions<Headquarters> | undefined>({
      query: () => {
        return `${BASE_HEADQUARTERS_URL}`;
      },
      providesTags: ["Headquarterss"],
      transformResponse: (response: EndpointHeadquarters, _meta, params: TableOptions<Headquarters> | undefined) => {
        let page = params?.page ?? 1;
        let endpointResponse = response.map((Headquarters: HeadquartersEndpoint) =>
            createAdapterToHeadquarters(Headquarters)
        );
        endpointResponse = manageLocalFilters<Headquarters>(endpointResponse, params);
        return {
          data: manageLocalPagination<Headquarters>(endpointResponse, params),
          page,
          total: endpointResponse.length,
        };
      },
    }),
    createHeadquarters: builder.mutation<any, Headquarters>({
      query: (data) => ({
        url: `${BASE_HEADQUARTERS_URL}`,
        method: "POST",
        body: createAdapterToHeadquartersEndpoint(data)
      
      }),
      invalidatesTags: ["Headquarterss"],
    }),
    updateHeadquarters: builder.mutation<any, Headquarters>({
      query: (data: Headquarters) => ({
        url: `${BASE_HEADQUARTERS_URL}/${data.id.replace(/-/g, "")}`,
        method: "PUT",
        body: createAdapterToHeadquartersEndpoint(data)
       
      }),
      invalidatesTags: (_res, _err, data: Headquarters) => ["Headquarterss", {type: 'Headquarters', id: data?.id?.replace(/-/g, "")}],
    }),
    deleteHeadquarters: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_HEADQUARTERS_URL}/${id.replace(/-/g, "")}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Headquarterss"],
    }),
  }),
});

export const {
  useGetHeadquartersQuery,
  useGetHeadquarterssQuery,
  useCreateHeadquartersMutation,
  useUpdateHeadquartersMutation,
  useDeleteHeadquartersMutation
} = headquartersApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginAttributes } from "../domain/login";
import { createAdapterLogin } from "../adapters/login.adapter";
import { Session } from "../domain/auth";
import { logout, setCredentials } from "../presentation/state/auth.reducer";
import { appConfig } from "../../../../config";
import { setNotification } from "../../NotificationBar/state/notification.reducer";
import { toastSweet } from "../../../../utilities/mensajeUtils";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_URL_UM,
    prepareHeaders: (headers: Headers) => {
      const token = localStorage.getItem(appConfig.APP_TOKEN_NAME);

      if (token) {
        headers.set("authorization", `${token}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => {
    return {
      login: builder.mutation<Session, LoginAttributes>({
        query: (data) => ({
          url: `/login`,
          method: "POST",
          body: createAdapterLogin(data),
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            console.log(data);
            
            localStorage.setItem(
              appConfig.APP_TOKEN_NAME,
              data.token as string
            );
            dispatch(setCredentials(data));
          } catch (error) {
            localStorage.removeItem(appConfig.APP_TOKEN_NAME);
          }
        },
      }),
      logout: builder.mutation<void, void>({
        query: () => ({
          url: `/logout`,
          method: "POST",
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(logout);
            window.location.href = appConfig.DEFAULT_LOGIN_PAGE;
            localStorage.removeItem(appConfig.APP_TOKEN_NAME);
          } catch (error) {
            console.error(error);
            localStorage.removeItem(appConfig.APP_TOKEN_NAME);
            toastSweet("error", "Hubo un error al cerrar la sessión")
          }
        },
      }),
    };
  },
});

export const { useLoginMutation, useLogoutMutation } = authApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appConfig } from "../../config";
import {
  BankReconciliationEndpoint,
  BankReconciliationRecord,
  BankReconciliationsEndpoint,
  LastBankReconciliationsEndpoint,
  ReconcileData,
} from "../domain/BankReconciliations";
import {
  createAdapterToBankReconciliation,
  createAdapterToLastBankReconciliations,
} from "../adapters/bankReconciliations.adapter";
import { BankReconciliationState } from "../domain/BankReconciliationStatus";

const BASE_BANK_RECONCILIATION_URL = "/conciliations";
const BASE_BANKS_LAST_RECONCILIATION_URL = "/banks/conciliations";
const BANK_RECONCILIATIONS_TAG = "BankReconciliations";
const BANK_RECONCILIATION_TAG = "BankReconciliations";
const BANK_RECONCILIATION_STATES_TAG = "BankReconciliationsStates";
const LAST_BANK_RECONCILIATION_TAG = "LastBankReconciliations";

export const bankReconciliationsApi = createApi({
  reducerPath: "bankReconciliationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [
    BANK_RECONCILIATIONS_TAG,
    BANK_RECONCILIATION_TAG,
    BANK_RECONCILIATION_STATES_TAG,
    LAST_BANK_RECONCILIATION_TAG,
  ],
  endpoints: (builder) => ({
    getBankReconciliationStates: builder.query<
      BankReconciliationState[],
      undefined
    >({
      query: () => `${BASE_BANK_RECONCILIATION_URL}/status`,
      providesTags: [BANK_RECONCILIATION_STATES_TAG],
    }),
    getBankReconciliation: builder.query<any, number | undefined>({
      query: (id) => `${BASE_BANK_RECONCILIATION_URL}/${id}`,
      providesTags: [BANK_RECONCILIATION_TAG],
      transformResponse: (response: BankReconciliationEndpoint) => {
        return createAdapterToBankReconciliation(response);
      },
    }),
    getBankReconciliations: builder.query<
      {
        extracts: BankReconciliationRecord[];
        diary: BankReconciliationRecord[];
      },
      { from: string; to: string; bankAccount?: string }
    >({
      query: ({ from, to, bankAccount }) => {
        return {
          url: `${BASE_BANK_RECONCILIATION_URL}?from=${from}&to=${to}&accountnumber=${bankAccount}`,
        };
      },
      // providesTags: [BANK_RECONCILIATIONS_TAG],
      transformResponse: (response: BankReconciliationsEndpoint) => {
        const extracts = response.extracts.map(
          (record: BankReconciliationEndpoint) =>
            createAdapterToBankReconciliation(record)
        );
        const diaries = response.diary.map(
          (record: BankReconciliationEndpoint) =>
            createAdapterToBankReconciliation(record)
        );
        return {
          extracts: extracts,
          diary: diaries,
        };
      },
    }),
    createReconciliation: builder.mutation<any, ReconcileData>({
      query: (data) => ({
        url: `${BASE_BANK_RECONCILIATION_URL}/reconcile`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        BANK_RECONCILIATIONS_TAG,
        BANK_RECONCILIATION_TAG,
        BANK_RECONCILIATION_STATES_TAG,
      ],
    }),
    deleteReconcile: builder.mutation<string, string>({
      query: (id) => ({
        url: `${BASE_BANK_RECONCILIATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        BANK_RECONCILIATIONS_TAG,
        BANK_RECONCILIATION_TAG,
        BANK_RECONCILIATION_STATES_TAG,
      ],
    }),
    importReconciliation: builder.mutation<
      any,
      { file: File; accountNumber: string }
    >({
      query: ({ file, accountNumber }) => {
        const formdata = new FormData();
        formdata.append("file", file);
        return {
          url: `/asientos/importsql/${accountNumber}`,
          method: "POST",
          body: formdata,
        };
      },
      invalidatesTags: [
        BANK_RECONCILIATIONS_TAG,
        BANK_RECONCILIATION_TAG,
        BANK_RECONCILIATION_STATES_TAG,
      ],
    }),
    getLastReconciliationTableData: builder.query<any, undefined>({
      query: () => `${BASE_BANKS_LAST_RECONCILIATION_URL}/lastconciliations`,
      transformResponse: (response: LastBankReconciliationsEndpoint) => {
        return createAdapterToLastBankReconciliations(response);
      },
    }),
    postPruebaReconcilation: builder.mutation<any, any>({
      query: (data) => ({
        url: `${BASE_BANK_RECONCILIATION_URL}/extractosdiarios`,
        method: "POST",
        body: data,
      }),
    })
  }),
});

export const {
  useGetBankReconciliationQuery,
  useGetBankReconciliationsQuery,
  useGetBankReconciliationStatesQuery,
  useCreateReconciliationMutation,
  useDeleteReconcileMutation,
  useImportReconciliationMutation,
  useGetLastReconciliationTableDataQuery,
  usePostPruebaReconcilationMutation
} = bankReconciliationsApi;

import { FieldErrors } from "react-hook-form";
import { InputError } from "../styled-components/InputError";
import { SelectOption } from "../../../models/selectOption.model";
import React, { useState } from "react";
import translate from "../../../../language/translate";
import {
  SelectLayout,
  StyledOption,
  StyledSelect,
} from "./styled-components/StyledSelect";
import { StyledFormSelectLabel } from "../styled-components/InputLayout";
import { useIntl } from "react-intl";
import { Dropdown } from "primereact/dropdown";

const formValidation = (
  errors: FieldErrors,
  errorKey: string,
  defaultErrorMessage: string
) => {
  return (
    <InputError show={!!errors[errorKey]}>
      {(errors[errorKey]?.message as string) ?? defaultErrorMessage}
    </InputError>
  );
};

interface SelectProps {
  id?: string;
  nullValue?: any;
  value?: any;
  register?: any;
  name: string;
  errors?: FieldErrors;
  label?: string;
  options: SelectOption[];
  required?: boolean;
  defaultValue?: any;
  hidden?: boolean;
  onChange?: any;
  disabled?: boolean;
  width?: string;
}

export const Select = ({
  id,
  nullValue,
  value,
  register,
  name,
  errors,
  options,
  label,
  required = false,
  hidden,
  onChange,
  disabled = false,
  width,
}: SelectProps) => {
  const intl = useIntl();
  const DEFAULT_NULL_VALUE = -1;
  const DEFAULT_ERROR_MESSAGE = intl.formatMessage({
    id: "complete this field",
  });
  const onChangeAttribute = onChange ? { onChange: onChange } : {};

  const [valueGuardado, setValueGuardado] = useState<any>();

  const onChangeG = (e: { target: { name: any; value: any; id: any }; }) => {
 
    
    setValueGuardado({...valueGuardado, value : e.target.value, id: e.target.id});

  };

  return (
    <>
      {/* <SelectLayout hidden={hidden} width={width}>
      {errors && formValidation(errors, name, DEFAULT_ERROR_MESSAGE)}
        <StyledFormSelectLabel htmlFor={id ?? name}>
          {label}
        </StyledFormSelectLabel>
        <Dropdown
          required={required}
          {...register}
          {...register}  {...onChangeAttribute}  value={value}
          id={valueGuardado?.id}
          disabled={disabled}
          options={options}
          optionLabel="label"
          optionValue="value"
          placeholder="Seleccione"
        />
      </SelectLayout> */}
      
    
   
      <SelectLayout hidden={hidden} width={width}>
      <StyledSelect required={required} {...register}  {...onChangeAttribute}  value={value} id={id ?? name} disabled={disabled}>
        <StyledOption value={nullValue ?? DEFAULT_NULL_VALUE} >{translate("none")}</StyledOption>
        {options?.map((option, index) => (
          <StyledOption
            key={`${name}_select_option_${index}`}
            value={option.value}
          >
            {option.label}
          </StyledOption>
        ))}
      </StyledSelect>
      <StyledFormSelectLabel htmlFor={id ?? name}>{label}</StyledFormSelectLabel>
      {errors && formValidation(errors, name, DEFAULT_ERROR_MESSAGE)}
    </SelectLayout>
    </>
  );
};

export default Select;

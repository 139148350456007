import { LOCALES } from "../locales";

const spanishLocale = {
  [LOCALES.SPANISH]: {
    hello: "Hola!",
    username: "Nombre de usuario",
    user: "Usuario",
    welcome: "¡Bienvenido nuevamente!", 
    password: "Contraseña",
    "new password": "Nueva Contraseña",
    "sign in": "Iniciar sesión",
    login: "Acceder",
    "is required": "es requerido",
    "is invalid": "no es válido",
    exit: "Salir",
    dashboard: "Resumen",
    "good morning" : "Buenos Días",
    "this is what is happening with your business today" : 
      "Esto es lo que está sucediendo con tu negocio hoy",
    users: "Usuarios",
    total: "Total",
    page: "Página",
    show: "Mostrar",
    records: "registros",
    firstname: "Nombres",
    lastname: "Apellidos",
    role: "Rol",
    of: "de",
    filters: "Filtros",
    contains: "contiene",
    doesNotContain: "no contiene",
    startsWith: "empieza por",
    endsWith: "termina con",
    equals: "igual a",
    notEquals: "no es igual a",
    numberEquals: "igual a",
    numberNotEquals: "no es igual a",
    greaterThan: "mayor que",
    greaterThanOrEqual: "mayor o igual que",
    lessThan: "menor que",
    lessThanOrEqual: "menor o igual que",
    after: "después de",
    before: "antes de",
    between: "entre",
    operator: "Operador",
    value: "Valor",
    and: "y",
    home: "Inicio",
    "No hay registros a importar": "No existen registros en el archivo seleccionado. El archivo debe contener registros para poder continuar",
    "there was an error loading the menus": "Hubo un error cargando los menús",
    settings: "Configuración",
    "General settings": "Configuración general",
    profile: "Perfil",
    "public avatar": "Avatar Público",
    "change anything related with your profile picture":
      "Cambia todo lo relacionado con tu imagen de perfil",
    "change general app settings" 
      : "Cambia la configuracion general de la aplicacion",
    "upload avatar": "Sube un avatar",
    "the maximum file size allowed is 200kb":
      "El tamaño máximo permitido es de 200 KB",
    "main settings": "Configuración principal",
    "change anything related with your profile data":
      "Cambia todo lo relacionado con los datos de tu perfil",
    "enter your first name": "Ingresa tus nombres",
    "enter your last name": "Ingresa tus apellidos",
    "enter your username, so people you know can recognize you":
      "Ingresa tu nombre de usuario para que las personas que conoces puedan reconocerte",
    "this email will be displayed on your public profile":
      "Este correo electrónico se mostrará en tu perfil público",
    update: "Actualizar",
    theme: "Tema",
    language: "Idioma",
    timezone: "Zona horaria",
    offset: "Configurar zona horaria",
    "change timezone": "Cambiar zona horaria",
    "change to your prefered language": "Cambia a tu idioma de preferencia",
    "change the main theme": "Cambia el tema principal",
    email: "Correo Electrónico",
    "stock administration": "Administración de stock",
    "products administration": "Administración de productos",
    "tags administration": "Administración de etiquetas",
    "trademark administration": "Administración de marcas",
    "categories administration": "Administración de categorías",
    "edit stock": "Editar stock",
    "new stock": "Nuevo stock",
    "edit product": "Editar producto",
    "new product": "Nuevo producto",
    "edit tag": "Editar etiqueta",
    "new tag": "Nueva etiqueta",
    "new trademark": "Nueva marca",
    "edit trademark": "Editar marca",
    "new category": "Nueva categoría",
    "edit category": "Editar categoría",
    "new currency": "Nueva moneda",
    "edit currency": "Editar moneda",
    currencyName: "Moneda",
    currencyCode: "Código",
    "currencies administration": "Administración de monedas",
    edit: "Editar",
    delete: "Eliminar",
    Editar: "Editar",
    Eliminar: "Eliminar",
    search: "Buscar",
    add: "Agregar",
    cancel: "Cancelar",
    confirm: "Confirmar",
    "confirm delete?": "¿Desea eliminar?",
    beneficiaryName: "Beneficiario",
    "new customer": "Nuevo cliente",
    "edit customer": "Editar cliente",
    customerName: "Cliente",
    "customers administration": "Administración de clientes",
    cuit: "NIF/CUIT",
    "new country": "Nuevo país",
    "edit country": "Editar país",
    countryName: "País",
    "countries administration": "Administración de países",
    "new supplier": "Nuevo Proveedor",
    "edit supplier": "Editar Proveedor",
    supplierName: "Proveedor relacionado",
    "suppliers administration": "Administración de proveedores",
    "new accountType": "Nuevo Tipo de cuenta bancaria",
    "edit accountType": "Editar tipo de cuenta bancaria",
    accountTypeName: "Tipo de cuenta bancaria",
    "AccountTypes administration":
      "Administración de tipos de cuentas bancarias",
    "new bankTransactionType": "Nuevo tipo de movimiento bancario",
    "edit bankTransactionType": "Editar tipo de movimiento bancario",
    bankTransactionTypeName: "Tipo de movimiento bancario",
    bankTransactionTypeNameShort: "Tipo Mov.",
    "BankTransactionType administration":
      "Administración de Tipos de movimientos Bancarios",
    "new state": "Nuevo Estado",
    "edit state": "Editar Estado",
    stateName: "Estado de la transacción",
    "text only": "Solo se permite texto",
    "states administration": "Administración de estados",
    "new contact": "Nuevo Contacto",
    "edit contact": "Editar Contacto",
    "contacts administration": "Administración de contactos",
    active: "Activo",
    "new investment type": "Nuevo Tipo de Inversión",
    "edit investment type": "Editar Tipo de Inversión",
    investmentTypeName: "Tipo de Inversión",
    "investment types administration": "Administración de Tipos de Inversión",
    "new area": "Nueva Área",
    "edit area": "Editar Área",
    areaName: "Área",
    "areas administration": "Administración de áreas",
    "new bankTransaction": "Nueva operación bancaria",
    "edit bankTransaction": "Editar operación bancaria",
    bankTransactionName: "Operación bancaria",
    "bankTransactions administration":
      "Administración de operaciones bancarias",
    short: "Nombre corto",
    code: "Código",
    "new payment method": "Nuevo formato de pago",
    "edit payment method": "Editar formato de pago",
    paymentMethodName: "Formato de pago",
    paymentMethodCode: "Código",
    "payment methods administration": "Administración de formatos de pago",
    "new investment movement type": "Nuevo Tipo de Movimiento de Inversión",
    "edit investment movement type": "Editar Tipo de Movimiento de Inversión",
    investmentMovementTypeName: "Tipo de Movimiento de Inversión",
    "investment movements types administration":
      "Administración de Tipos de Movimientos de Inversión",
    none: "Seleccione",
    noneAccount: "Seleccione Cuenta",
    "new bank": "Nuevo Banco",
    "edit bank": "Editar Banco",
    bankName: "Banco",
    "banks administration": "Administración de bancos",
    BCRA: "Código Banco",
    countryId: "País",
    "new concept": "Nuevo Concepto",
    "edit concept": "Editar Concepto",
    conceptName: "Concepto",
    "concepts administration": "Administración de conceptos",
    conceptTypeId: "Tipo de Concepto",
    order: "Orden",
    "new exchangeRate": "Nuevo tipo de cambio",
    "edit exchangeRate": "Editar tipo de cambio",
    date: "Fecha",
    maxDate: "Fecha tope",
    "exchangeRates administration": "Administración de tipos de cambio",
    amount: "Monto",
    parentAccount: "Cuenta madre",
    "new investment portfolio": "Nueva Cartera de Inversión",
    "edit investment portfolio": "Editar Cartera de Inversión",
    investmentPortfolioName: "Cartera de Inversión",
    ric: "RIC",
    dueDate: "Fecha Vencimiento",
    issueDate: "Fecha Emisión",
    depositDate: "Fecha Depósito",
    rejectionReason: "Motivo rechazo/anulado",
    ChequeTypeName: "Tipo de cheque",
    ownChequeName: "Cheque Propio",
    ThirdPartyChequeName: "Cheque de Tercero",
    ChequeNumber: "Cheque Número",
    number: "Número",
    "investment portfolios administration":
      "Administración de Carteras de Inversión",
    father: "Superior",
    label: "Etiqueta",
    name: "Nombre",
    icon: "Icono",
    "menus administration": "Administración de Menús",
    action: "Acción",
    "file not selected": "Archivo no seleccionado",
    "choose file": "Seleccione archivo",
    "add menu": "Agregar menú",
    parentMenu: "Menú superior",
    alias: "Alias",
    "new investmentInstrument": "Nuevo instrumento de inversión",
    "edit investmentInstrument": "Editar instrumento de inversión",
    investmentInstrumentName: "Instrumento de inversión",
    "investmentInstruments administration":
      "Administración de instrumentos de inversión",
    ISIN: "ISIN",
    "new bankAccount": "Nueva cuenta bancaria",
    "edit bankAccount": "Editar Banco",
    "bankAccounts administration": "Administración de cuentas bancarias",
    bankAccountName: "Cuenta Bancaria",
    accountNumber: "Cuenta número",
    CBU: "CBU",
    SWIFT: "SWIFT",
    CUIT: "NIF/CUIT",
    bankId: "Banco",
    currencyId: "Moneda",
    accountTypeId: "Tipo de cuenta",
    "new bank transaction classification":
      "Nueva Clasificación de Movimiento Bancario",
    "edit bank transaction classification":
      "Editar Clasificación de Movimiento Bancario",
    "bank transactions classification administration":
      "Administración de Clasificación de Movimientos Bancarios",
    bankTransactionClassificationName: "Clasificación",
    bankTransactionTypeId: "Tipo Movimiento",
    transactionCode: "Código Operación",
    p1Code: "Código ERP",
    bankTransactionClassificationDetailed:
      "Clasificación de Movimiento Bancario Detallada",
    bankTransactionClassificationDetailedShort:
      "Clas. Mov. Bancario Detallada",
    "new statementBalance": "Nuevo extracto de saldo bancario",
    "edit statementBalance": "Editar extracto de saldo bancario",
    "statementBalances administration":
      "Administración de extractos de saldos bancarios",
    balance: "Saldo",
    hour: "Hora",
    "new monthClosure": "Nuevo cierre de mes",
    "monthClosures administration": "Administración de Cierres de mes",
    month: "Mes",
    year: "Año",
    period: "Periodo",
    firstEstimatePercentage: "Porcentaje primera estimación",
    suggestedPercentage: "Porcentaje sugerido",
    concept: "Concepto",
    lastEstimatePercentage: "Porcentaje última estimación",
    "locations administration": "Administración de locaciones",
    initialize: "Inicializar",
    "must be greater than or equal to": "debe ser mayor o igual a",
    "must be less than or equal to": "debe ser menor o igual a",
    "must be greater than": "debe ser mayor a",
    "An error occurred. Contact the administrator":
      "Sucedió un error. Comuníquese con el administrador",
    "enter a month in MM format": "Ingrese un mes con formato MM",
    "enter a year in YYYY format": "Ingrese un año con formato YYYY",
    "enter a valid percentage": "Ingrese un porcentaje válido",
    "edit location": "Editar locación",
    "new statementMovement": "Nuevo extracto movimiento bancario",
    "edit statementMovement": "Editar extracto movimiento bancario",
    "statementMovements administration":
      "Administración de extracto movimiento bancario",
    valueDate: "Fecha valor",
    processDate: "Fecha proceso",
    bankCode: "Código de banco",
    shortAccountNumber: "Número de cuenta corto",
    movementDate: "Fecha de movimiento",
    movementType: "Tipo de movimiento",
    voucherNumber: "Comprobante",
    opCode: "Código de operación",
    freeInfo: "Información libre",
    branch: "Sucursal origen",
    depositorCode: " Código depositante",
    longAccountNumber: "Número Cuenta Largo",
    bankOpCode: "Código operación banco",
    currencyType: "Tipo moneda",
    loadFile: "Cargar archivo",
    daily: "Diario",
    foreign: "Exterior",
    file: "Archivo",
    "There is no data to import in the table":
      "No hay datos para importar en la tabla",
    "Load File": "Cargar Archivo",
    saveData: "Guardar Datos",
    "error loading data": "Error al cargar los datos",
    column: "Columna",
    "add value": "Agregar valor",
    "column is required": "Columna es requerida",
    "operator is required": "Operador es requerida",
    "complete this field": "Complete este campo",
    "complete all fields": "Para agregar, se deberá completar los campos pendientes",
    typeId: "Tipo concepto",
    only: "solo",
    some: "alguno de",
    "cash flow administration": "Administración de Cash Flow",
    bank: "Banco",
    initialBalance: "Saldo Inicial",
    totalRevenues: "Total Ingresos",
    totalRevenuesShort: "T. Ingresos",
    totalExpenses: "Total Egresos",
    totalExpensesShort: "T. Egresos",
    dailyTransactions: "Movimientos Diarios",
    dailyTransactionsShort: "Mov. Diarios",
    accountsTransactions: "Transferencias entre cuentas",
    accountsTransactionsShort: "Tran. entre ctas.",
    projectedBalance: "Saldo Proyectado",
    details: "Detalles",
    state: "Estado",
    minimumBalance: "Saldo Mínimo",
    taxDebitsCredits: "TX (D/C) 0,60 %l",
    transferredExpenses: "Egresos Traspasados",
    transferredRevenues: "Ingresos Traspasados",
    expenses: "Egresos",
    revenues: "Ingresos",
    preview: "Previsualizar",
    send: "Enviar",
    accept: "Aceptar",
    "sending emails": "Envíos de emails",
    sendEmail: "Enviar Email",
    sendingMail: "Envío desde",
    template: "Plantilla",
    cashflowInitialization: "Inicialización de cashflow",
    "sure to initialize?":
      "¿Está usted seguro de proceder con la inicialización de cashflow? Esta acción es irreversible",
    cashflowUpdate: "Actualización de cashflow",
    "sure to update?":
      "¿Está usted seguro de proceder con la actualización de cashflow? Esta acción es irreversible",
    firstEstDate: "Pri. Estimación",
    lastEstDate: "Últ. Estimación",
    currencyLocationId: "Moneda asignación",
    currencyOpId: "Moneda operación",
    exchangeRateOp: "Tipo cambio operación",
    comment: "Comentario",
    creditNote: "Nota de crédito",
    firstEstAmount: "Importe prim. est.",
    firstEstAmountComplete: "Importe primera estimación",
    lastEstAmount: "Importe últ. est.",
    lastEstAmountComplete: "Importe última estimación",
    "incomes administration": "Administración de estimaciones ingresos",
    "new income": "Nueva estimación ingreso",
    "edit income": "Editar estimación ingreso",
    rescueFCI: " Rescate FCI",
    subscribeFCI: "Suscripción FCI",
    expense: "Egreso",
    revenue: "Ingreso",
    "Daily Movement": "Movimiento Diario",
    Transfer: "Transferencia",
    fromBankAccountId: "De Cuenta Bancaria",
    toBankAccountId: "Hacia Cuenta Bancaria",
    id: "ID",
    dateEquals: "igual a",
    dateNotEquals: "no es igual a",
    dateBetween: "entre",
    "selected columns": "Columnas seleccionadas",
    "searcheable columns": "Columnas Buscables:",
    Import: "Importar Archivo",
    import: "Importar",
    Export: "Exportar",
    "new financialInvestment": "Nueva inversión financiera",
    "edit financialInvestment": "Editar inversión financiera",
    financialInvestmentName: "Inversión financiera",
    "financialInvestments administration":
      "Administración de inversiones financieras",
    "users administration": "Administración de usuarios",
    fees: "Comisiones",
    taxes: "Impuestos",
    payOrder: "Orden de pago",
    Program: "Reprogramar",
    "edit expense": "Editar egreso",
    "new expense": "Nuevo egreso",
    "expenses administration": "Administración de egresos",
    "select the file to import": "Seleccione el archivo a importar",
    "Table Transformations": "Transformaciones de tabla",
    HWBankDolarBillete: "Banco Dólar Billete",
    HWBankDolarDivisa: "Banco Dólar Divisa",
    HWmmPesos: "MM Pesos",
    HWBankPesos: "Banco Pesos",
    "new chequeState": "Nuevo estado de cheque",
    "edit chequeState": "Editar estado de cheque",
    chequeStateName: "Estado de cheque",
    "new chequeType": "Nuevo tipo de cheque",
    "edit chequeType": "Editar tipo de cheque",
    chequeTypeName: "Tipo de cheque",
    PermissionName: "Permiso",
    permissions: "Permisos",
    roleName: "Rol",
    assigned: "Asignados",
    available: "Disponibles",
    selected: "seleccionados",
    "all items selected": "Todos los artículos seleccionados",
    "move selected left": "Mover seleccionado a la izquierda",
    "move selected right": "Mover seleccionado a la derecha",
    "invalid email": "Correo electrónico inválido",
    "invalid password": "Contraseña invalida",
    "invalid username": "Nombre de usuario inválido",
    "you do not have permissions to enter this menu":
      "No tiene los permisos para entrar a este menú",
    "in row": "en fila",
    importSuccessful: "Importación exitosa",
    "change anything related with your password":
      "Cambia todo lo relacionado con tu contraseña",
    "manage password": "Configuración de contraseña",
    "enter your new password": "Ingresa tu nueva contraseña",
    "enter your old password": "Ingresa tu vieja contraseña",
    "re-enter your new password": "Vuelve a ingresar tu nueva contraseña",
    "old password": "Vieja Contraseña",
    "inconsistent passwords": "Ingresaste dos contraseñas diferentes",
    "Passwords do not match": "La vieja contraseña no coincide",
    "successful password update": "Actualización de contraseña exitosa",
    "input date older than last month closure date":
      "La fecha tiene que ser posterior a la fecha de cierre",
    "dates are wrong": "fechas bloqueadas por cierre de mes",
    "you need to correct this before continuing":
      "Es necesario corregir esto antes de continuar (Filas erróneas en rojo)",
    "successful import, data was sent":
      "Se importó exitosamente, los datos fueron enviados",
    "import failed": "Importación falló",
    noRole: "Sin Rol",
    "initial projected balance": "Saldo proyectado a hoy",
    "initial projected tomorrow": "Saldo proyectado a mañana",
    "initial projected this week": "Saldo proyectado a esta semana",
    "initial projected next week": "Saldo proyectado a siguiente semana",
    "initial projected month end": "Saldo proyectado a fin de mes",
    "initial projected at two months": "Saldo proyectado a dos meses",
    "daily transactions": "Movimientos Diarios",
    "daily treasury": "Tesorería Diaria",
    "options predefined": "Opciones predefinidas",
    today: "Hoy",
    tomorroy: "Mañana",
    "this week": "Esta semana",
    "next week": "Siguiente semana",
    "end of the month": "Fin de mes",
    to: "Hasta",
    "period projection": "Proyección del período",
    "initial balance": "Saldo inicial",
    "income collections": "Ingresos Cobros",
    "expenses paid": "Egresos pagos",
    "projected balance": "Saldo proyectado",
    "select bank": "Seleccione banco",
    "date to": "Fecha hasta",
    paymentTypeName: "Tipo de Pago",
    Payment: "Pagos",
    failedOperation: "La operación no se pudo hacer",
    createExpense: "Crear Egreso",
    updateExpense: "Modificar Egreso",
    createIncome: "Crear Ingreso",
    updateIncome: "Modificar Ingreso",
    Collection: "Cobros",
    createMovement: "Agregar Movimiento",
    updateMovement: "Editar Movimiento",
    debit: "Débito",
    credit: "Crédito",
    hasTo: "Debe",
    toHave: "Haber",
    reconcile: "Conciliar",
    "reconciliation detail": "Detalle de la conciliación",
    "bank extract": "Extracto bancario",
    "diary book": "Libro diario",
    "You have selected a statement that has already been reconciled":"Tiene seleccionado un extracto que ya fue conciliado",
    "You have selected a journal that has already been reconciled":"Tiene seleccionado un diario que ya fue conciliado",
    accountBank: "Cuenta bancaria",
    extracts: "Extractos",
    diary: "Diario",
    "error when reconciling": "Error al conciliar",
    reconcileSuccessful: "Conciliación exitosa",
    reconcileDeleted: "Conciliación eliminada",
    "new reconciliation": "Nueva conciliación",
    "The amounts are different": "Los montos son diferentes",
    export: "Exportar",
    CountryName: "Nombre del País",
    ProgramWarning:
      "Todos los registros pendientes a la fecha se reprogramarán para la fecha seleccionada",
    MonthEndClose: "Cierre de Mes",
    MonthEndCloseWarning:
      "Todos los registros pendientes al periodo seleccionados serán anulados por cierre de mes",
    MonthClosureWarning:
      "No se podrán modificar los registros que tengan una fecha anterior a la fecha ingresada como límite",
    "input has to be positive": "El valor tiene que ser mayor a 0",
    addFilter: "Agregar Filtro",
    "select account": "Seleccione cuenta",
    conceptId: "Concepto",
    stateId: "Estado",
    investmentPortfolioId: "Cartera de inversión",
    investmentMovementTypeId: "Tipo de movimiento de inversión",
    investmentInstrumentId: "Instrumento de inversión",
    bankAccountId: "Cuenta número",
    "Locked by Date": "Bloqueado por cierre",
    unsavedChanges: "Hay cambios sin confirmar",
    noChanges: "Los cambios no se completaron correctamente",
    "lastEstDate must be higher than firstEstDate":
      "Fecha últ. estimación debe ser mayor a fecha pri. estimación",
    "successful create": "Creación exitosa, los datos fueron enviados",
    "successful update": "Edición exitosa, los datos fueron enviados",
    "success delete": "Borrado exitoso",
    areaId: "Área",
    paymentMethodId: "Método de pago",
    supplierId: "Proveedor",
    bankTransactionClassificationId: "Clasificación movimiento bancario",
    hourId: "Hora",
    beneficiaryId: "Beneficiario",
    chequeStateId: "Estado cheque",
    chequeTypeId: "Tipo cheque",
    customerId: "Cliente",
    bankTransactionId: "Operación bancaria",
    investmentTypeId: "Tipo de inversión",
    createChequeSuccessful: "Cheque creado satisfactoriamente",
    updateChequeSuccessful: "Cheque actualizado satisfactoriamente",
    deleteChequeSuccessful: "Cheque eliminado satisfactoriamente",
    createSuccessful: "Creado satisfactoriamente",
    updateSuccessful: "Actualizado satisfactoriamente",
    deleteSuccessful: "Eliminado satisfactoriamente",
    "12characters": "debe ser 12 caracteres alfanuméricos",
    reconciliationDashboard: "Dashboard",
    lastReconciliations: "Últimas conciliaciones",
    notSelectedFile: "Se debe ingresar un archivo",
    "enter dates": "Ingrese las fechas",
    "last 30 days": "Últimos 30 días",
    "error when delete reconciling": "Error al eliminar la conciliación",
    "error getting reconciliations": "Error obteniendo conciliaciones",
    resumeLastReconciliation: "Resumen de última conciliación",
    topTenNoReconciliations: "Top 10 no conciliaciones",
    "processed movements": "Movimientos procesados",
    "automatic reconciliations": "Conciliaciones automáticas",
    "partial reconciliations": "Conciliaciones parciales",
    "no conciliations": "No conciliaciones",
    "automatic percentage": "Porcentaje automático",
    "unreconciled amount": "Monto no conciliado",
    viewDetails: "Ver detalles",
    importReconciliationError: "Error al importar conciliación",
    lastreconciliations: "Últimas conciliaciones",
    remove: "Eliminar",
    reconciliations: "Conciliaciones",
    bankReconciliations: "Conciliaciones bancarias",
    banks: "Bancos",
    lastConciliationDate: "Fecha Última Conciliación",
    automaticConciliationsPercentage: "% Mov. Conciliados Automáticamente",
    noConciliationsPercentage: "% Mov. No Conciliados",
    noConciliationsQuantity: "Cantidad No Conciliados",
    noConciliationsAmount: "Monto No Conciliados",
    accountsbanks: "Cuentas Bancarias",
    "error when obtaining the latest reconciliations":
      "Error al obtener las últimas conciliaciones",
    "go to last reconciliation": "Ir a la última conciliación",
    requiredByLastEstDate: "El campo es requerido por Fecha Última Estimación",
    requiredByLastEstAmount:
      "El campo es requerido por Importe Última Estimación",
    numberOfErrors: "Hay {number} fila(s) con errores",
    paymentSavedSuccessful: "Pago guardado exitosamente",
    paymentAddedSuccessful: "Pago añadido exitosamente",
    collectionSavedSuccessful: "Cobro guardado exitosamente",
    collectionAddedSuccessful: "Cobro añadido exitosamente",
    "Concepto already exists": "El concepto ya existe",
    "issueDate required": "Fecha emisión es requerida",
    "depositDate must be greater than issueDate":
      "Fecha depósito debe ser mayor a Fecha emisión",
    "dueDate required": "Fecha vencimiento es requerida",
    "dueDate must be greater than depositDate":
      "Fecha vencimiento debe ser mayor que Fecha depósito",
    "dueDate must be greater than issueDate":
      "Fecha vencimiento debe ser mayor que Fecha emisión",
    "CierreMes already exists": "Fecha de bloqueo ya existe",
    "El Cheques ya existe": "El Cheque ya existe",
    "amount must be greater than 0": "Monto debe ser mayor a 0",
    "Error tipo cheque can not be duplicated":
      "Tipo de cheque no puede estar duplicado",
    "Error tipo de cheque name already exist":
      "El nombre del tipo de cheque ya existe",
    "Error bank name already exist": "El nombre del banco ya existe",
    "Error bank short name already exist":
      "El nombre corto del banco ya existe",
    "Error bank BCRA code already exist": "El código del banco ya existe",
    "Error swift account already exist": "SWIFT de la cuenta ya existe",
    "Error CBU already exist": "Este CBU ya existe",
    "Error account number exist": "Cuenta número ya existe",
    "Error duplicated estimacion estado name": "Nombre del estado duplicado",
    "Error estimacion estado name already exist": "Nombre del estado ya existe",
    "Error duplicated formatos pagos name":
      "Nombre de la forma de pago duplicado",
    "Error duplicated formatos pagos short name":
      "Nombre corto de la forma de pago duplicado",
    "Error formatos pagos name already exist":
      "Nombre de la forma de pago ya existe",
    "Error formatos pagos short name already exist":
      "Nombre corto de la forma de pago ya existe",
    "Error email can not be duplicated":
      "El correo electrónico no puede estar duplicado",
    "Error, email to update already exists":
      "El correo electrónico a actualizar ya existe",
    "El NIF/CUIT ya existe": "NIF/CUIT ya existe",
    "Error name already exist": "El nombre ya existe",
    "Tipo de cambio exist on date": "Tipo de cambio ya existe en la fecha",
    "Error can not be add duplicated area":
      "No se puede agregar un área duplicada",
    "Error area to update already exist": "El área a actualizar ya existe",
    "must be between": "debe estar entre",
    "must be higher than": "debe ser mayor que",
    "This filter is invalid": "Este filtro es inválido",
    "This condition is invalid": "Esta condición es inválida",
    "This name is invalid": "El atributo del filtro es inválido",
    "Tabla can not be empty": "La tabla no puede estar vacía",
    "Filter can not be empty": "El filtro no puede estar vacío",
    "RootDir is required": "Carpeta padre no puede ser vacía",
    "RootDir can not be blank": "Carpeta padre no puede ser vacía",
    "The selected bank account does not match with data in the file":
      "La cuenta número no coincide con los datos de la fila",
    "Error no coincide el importe del asiento y del extracto":
      "No coincide el importe del asiento y del extracto",
    "Error asineto a conciliar incorrecto": "Asiento a conciliar incorrecto",
    "Error asiento a conciliar incorrecto": "Asiento a conciliar incorrecto",
    "Error cuentas bancarias no coinciden": "Cuentas número no coinciden",
    "Error en la conciliacion no hay asientos":
      "La conciliación no tiene asientos",
    "Error en la conciliacion no hay extractos movimientos":
      "La conciliación no tiene extractos",
    "Error el asiento ya tiene una conciliacion":
      "El asiento ya tiene una conciliación",
    "Error el extracto bancario ya tiene una conciliacion":
      "El extracto ya tiene una conciliación",
    "Error bank account not exist": "No existe la cuenta número",
    "must not be empty": "El campo no debe estar vacío",
    "Extracto Movimiento Bancario already exists":
      "Ya existe el extracto bancario",
    "Extracto Movimiento Bancario not exists": "No existe el extracto bancario",
    "Extracto Movimiento Bancario not found":
      "No se encontró el extracto bancario",
    "Fecha not found": "No se encontró la fecha",
    "Cuenta Bancaria not found": "No se encontró la cuenta número",
    "Clasificacion Movimiento Bancario not found":
      "No se encontró la clasificación de movimiento bancario",
    "Concepto not found": "No se encontró el concepto",
    "Tipo Movimiento Bancario not found":
      "No se encontró el Tipo de movimiento bancario",
    "Error empty code": "Código vacío",
    "Extracto Saldo Bancario already exists":
      "Ya existe el extracto de saldo bancario",
    "Extracto Saldo Bancario not exists":
      "No existe el extracto de saldo bancario",
    "Extracto Saldo Bancario not found": "No se encontró el saldo bancario",
    "Ingreso already exists": "ya existe el ingreso",
    "Ingreso not exists": "no existe el ingreso",
    "Ingreso not found": "No se encontró el ingreso",
    "Movimiento not found": "No se encontró el movimiento",
    "Fecha Primera Estimacion not found":
      "No se encontró la fecha de primera estimación",
    "Fecha Última Estimacion not found":
      "No se encontró la fecha de última estimación",
    "Área not found": "No se encontró el área",
    "Moneda Locacion not found": "No se encontró la moneda locación",
    "Moneda Operacion not found": "No se encontró la moneda operación",
    "Estado not found": "No se encontró el estado",
    "Cliente not found": "No se encontró el cliente",
    "Incorrect Period": "Período incorrecto",
    "Cashflow already exists": "Ya existe el flujo de caja",
    "Cashflow not exists": "No existe el flujo de caja",
    "Cashflow not found": "No se encontró el flujo de caja",
    "Egreso already exists": "Ya existe el egreso",
    "Egreso not exists": "No existe el egreso",
    "Egreso not found": "No se encontró el egreso",
    "Formato Pago not found": "No se encontró el formato de pago",
    "Proveedor not found": "No se encontró el",
    "Movimiento already exists": "Ya existe el movimiento",
    "Movimiento not exists": "No existe el movimiento",
    "Cheque Propio o Cheque Tercero o Cuenta Bancaria es requerido":
      "Cheque propio o Cheque tercero o cuenta número es requerido",
    "Cobro already exists": "Ya existe el cobro",
    "Cobro not exists": "No existe el cobro",
    "Cobro not found": "No se encontró el cobro",
    "Inversión Financiera already exists": "Ya existe la inversión financiera",
    "Inversión Financiera not exists": "No existe la inversión financiera",
    "Inversión Financiera not found": "No se encontró la inversión financiera",
    "Cartera Inversión not found": "No se encontró la cartera de inversión",
    "Instrumento Inversión not found":
      "No se encontró el instrumento de inversión",
    "Tipo Movimiento Inversión not found":
      "No se encontró el tipo de movimiento inversión",
    "Pago already exists": "Ya existe el pago",
    "Pago not exists": "No existe el pago",
    "Pago not found": "No se encontró el pago",
    "Locacion already exists": "Ya existe la locación",
    "Locacion not exists": "No existe la locación",
    "Locacion not found": "No se encontró la locación",
    "Operacion de Banco already exists": "Ya existe la operación bancaria",
    "Operacion de Banco not exists": "No existe la operación bancaria",
    "Operacion de Banco not found": "No se encontró la operación bancaria",
    "Cartera de Inversión already exists": "Ya existe la cartera de inversión",
    "Cartera de Inversión not exists": "No existe la cartera de inversión",
    "Cartera de Inversión not found": "No se encontró la cartera de inversión",
    "Moneda not found": "No se encontró la moneda",
    "Tipo de Inversion not found": "No se encontró el tipo de inversión",
    "Instrumento de Inversión already exists":
      "Ya existe el instrumento de inversión",
    "Instrumento de Inversión not exists":
      "No existe el instrumento de inversión",
    "Instrumento de Inversión not found":
      "No se encontró el instrumento de inversión",
    "El ISIN ingresado ya existe": "Ya existe el ISIN",
    "Fecha Vencimiento not found": "No se encontró la fecha de vencimiento",
    "Clase de Inversión not found": "No se encontró la clase de inversión",
    "Tipo de Movimiento Bancario already exists":
      "Ya existe el tipo de movimiento bancario",
    "Tipo de Movimiento Bancario not exists":
      "No existe el tipo de movimiento bancario",
    "Tipo de Movimiento Bancario not found":
      "no se encontró el tipo de movimiento bancario",
    "Tipo de Concepto already exists": "Ya existe el tipo de concepto",
    "Tipo de Concepto not exists": "No existe el tipo de concepto",
    "Tipo de Concepto not found": "No se encontró el tipo de Concepto",
    "Type of Movement Investment already exists":
      "Ya existe el tipo de movimiento de inversión",
    "Type of Movement Investment not exists":
      "No existe el tipo de movimiento de inversión",
    "Type of Movement Investment not found":
      "No se encontró el tipo de movimiento de inversión",
    "Cuenta Bancaria already exists": "Ya existe la cuenta número",
    "Cuenta Bancaria not exists": "No existe la cuenta número",
    "Banco not found": "No se encontró el banco",
    "Tipo de Cuenta Bancaria not found":
      "No se encontró el tipo de cuenta bancaria",
    "Clasificacion Movimiento Bancario already exists":
      "Ya existe clasificación movimiento bancario",
    "Clasificacion Movimiento Bancario not exists":
      "No existe clasificación movimiento bancario",
    "Operacion Banco not found": "No se encontró la operación bancaria",
    "Codigo de Operacion Duplicado": "El código de operación está duplicado",
    "Codigo P1 Duplicado": "El código ERP está duplicado",
    "Banco already exists": "Ya existe el banco",
    "Banco not exists": "No existe el banco",
    "PaisID not found": "No se encontró el país",
    "Codigo Banco already exist for this country":
      "Ya existe el código bancario para este país",
    "Error bank BCRA code already exist ": "Ya existe el código del banco",
    "must be a string of at most 5 characters":
      "El texto debe ser de menos de 5 caracteres",
    "Clase de Inversión already exists": "Ya existe la clase de inversión",
    "Clase de Inversión not exists": "No existe la clase de inversión",
    "Tipo de Cuenta Bancaria already exists":
      "Ya existe el tipo de cuenta bancaria",
    "Tipo de Cuenta Bancaria not exists":
      "No existe el tipo de cuenta bancaria",
    "Tipo de Inversion already exists": "Ya existe el tipo inversión",
    "Tipo de Inversion not exists": "No existe el tipo inversión",
    "CierreMes not exists": "No existe el bloqueo de mes",
    "CierreMes not found": "No se encontró el bloqueo de mes, debe agregar una fecha previamente para este proceso",
    "CierreMes Table empty": "No se encontró el bloqueo de mes, debe agregar una fecha previamente para este proceso",
    "Order already exists": "Ya existe el orden",
    "Concepto not exists": "No existe el concepto",
    "TipoConceptoID not found": "No se encontró el concepto",
    "Tipo de Cambio already exists": "Ya existe el tipo de cambio",
    "Tipo de Cambio not exists": "No existe el tipo de cambio",
    "Tipo de Cambio not found": "No se encontró el tipo de cambio",
    "Contacto already exists": "Ya existe el contacto",
    "Contacto not exists": "No existe el contacto",
    "Contacto not found": "No se encontró el contacto",
    "must be a string of at most 3 characters":
      "El texto debe ser de menos de 3 caracteres",
    "El País ya Existe": "Ya existe el país",
    "País not exists": "No existe el país",
    "País not found": "No se encontró el país",
    "Persona already exists": "Ya existe la persona",
    "Persona not exists": "No existe la persona",
    "Persona not found": "No se encontró la persona",
    "Tiempo already exists": "Ya existe el tiempo",
    "Tiempo not exists": "No existe el tiempo",
    "Tiempo not found": "No se encontró el tiempo",
    "FechaID cannot be null": "La fecha no puede estar vacía",
    "La Moneda existe": "Ya existe la moneda",
    "Monedas not exists": "No existe la moneda",
    "Monedas not found": "No se encontró la moneda",
    "Codigo de Moneda existe": "Ya existe el código de moneda",
    "Estado already exists": "Ya existe el estado",
    "Estado not exists": "No existe el estado",
    "Formato de Pago already exists": "Ya existe el formato de pago",
    "Formato de Pago not exists": "No existe el formato de pago",
    "Formato de Pago not found": "No se encontró el formato de pago",
    "FactHamblinWatsa already exists": "Ya existe la casa matriz",
    "FactHamblinWatsa not exists": "No existe la casa matriz",
    "FactHamblinWatsa not found": "No se encontró la casa matriz",
    "Area already exists": "Ya existe el área",
    "Area not exists": "No existe el área",
    "Area not found": "No se encontró el área",
    "TipoCheque already exists": "Ya existe tipo de cheque",
    "TipoCheque not exists": "No existe tipo de cheque",
    "TipoCheque not found": "No se encontró tipo de cheque",
    "Estados already exists": "Ya existe el estado",
    "Estados not exists": "No existe el estado",
    "Estados not found": "No se encontró el estado",
    "El Cheque ya existe": "No existe el cheque",
    "ChequesPropios not exists": "No existe el cheque propio",
    "ChequesPropios not found": "No se encontró el cheque propio",
    "ChequesTerceros not exists": "No existe el cheque tercero",
    "ChequesTerceros not found": "No se encontró el cheque tercero",
    "Name must not be empty": "El nombre no tiene que estar vacío",
    "Alias must not be empty": "El alias no tiene que estar vacío",
    "Action must not be empty": "La acción no tiene que estar vacía",
    "Menu name already exists": "Ya existe el menú",
    "Menu name not exists": "No existe el menú",
    "Menu not found": "No se encontró el menú",
    "Invalid parent menu": "El menú padre es inválido",
    initializeCashFlowSuccessful: "Inicialización de cashflow exitosa",
    updateCashFlowSuccessful: "Actualización de cashflow exitosa",
    emailCashFlowSuccessful: "El correo electrónico fue enviado exitosamente",
    cashFlowTransferSuccessful: "Transferencia exitosa",
    cashFlowMovDailySuccessful: "Movimiento diario exitoso",
    "Movimiento realizado, saldo de cuenta en valor negativo":
      "Movimiento realizado, saldo de cuenta en valor negativo",
    "Cuenta origen no encontrada": "Cuenta origen no encontrada",
    "Debe seleccionar una cuenta valida": "Debe seleccionar una cuenta válida",
    "El monto debe ser superior a 0": "El monto debe ser superior a 0",
    "Transferencia realizada, saldo de cuenta origen en valor negativo":
      "Transferencia realizada, saldo de cuenta origen en valor negativo",
    "No se ha realizado la transferencia":
      "No se ha realizado la transferencia",
    cashflow: "Flujo de caja",
    at: "hasta",
    yes: "Si",
    no: "No",
    "Invalid credentials":
      "El usuario y/o la contraseña ingresados no son correctos",
    "Se debe seleccionar un tipo de movimiento":
      "Se debe seleccionar un tipo de movimiento",
    thirdPartyCheques: "Cheques terceros",
    noChartData: "No existen datos",
    incomes: "Ingresos",
    "Error mother account already exist":
      "Ya existe una Cuenta Madre en el sistema",
    process: "Proceso",
    monthClosures: "Bloqueo de mes",
    "error getting last reconciliation close":
      "Error al obtener el último cierre de conciliación",
    "Codigo must not be empty": "Código no puede ser vacío",
    "Proceso must not be empty": "Proceso no puede ser vacío",
    "Procesos already exists": "Proceso ya existe",
    "Procesos not exists": "Proceso no existe",
    "Procesos not found": "Proceso no encontrado",
    "Procesos Table empty":
      "No existe un Proceso. Agregue uno para poder validar",
    "Invalid processing date": "No se puede procesar. Bloqueado por cierre",
    monthLock: "Bloqueo de mes",
    "amount extracts": "Montos extracto",
    "amount diaries": "Montos diario",
    accountingAccount: "Cuenta Contable",
    "Error accounting account already exist": "La cuenta contable ya existe",


    "Estimaciones" : "Estimaciones",
    "Financieras" : "Financieras",
    "Parametría" : "Parametría",
    "Parametria" : "Parametria",
    "Transacciones" : "Transacciones",
    "Extractos Bancarios" : "Extractos Bancarios",
    "statementMovements" : "Extractos bancarios",
    "Cheques" : "Cheques",
    "Estimaciones Ingresos":"Estimaciones Ingresos",
    "Estimaciones Egresos" : "Estimaciones egresos",
    "Estimaciones Inversiones Financieras" : "Inversiones Financieras",
    "Inversiones Financieras" : "Inversiones Financieras",
    "financialInvestments" : "Inversiones Financieras",
    "Flujo de Caja" : "Flujo de Caja",
    "Bloqueo de Mes" : "Bloqueo de Mes",
    "Cheques Propios" : "Cheques Propios",
    "ownCheques" : "Cheques Propios",
    "Cheques Terceros" : "Cheques Terceros",
    "Administración de Bancos" : "Administración de Bancos",
    "Cartera Inversiones" : "Cartera Inversiones",
    "investmentPortfolios" : "Cartera Inversiones",
    "Tipo Cuentas Bancarias" : "Tipo Cuentas Bancarias", 
    "accountTypes" : "Tipo Cuentas",
    "Cuentas Bancarias" : "Cuentas Bancarias",
    "bankAccounts" : "Cuentas Bancarias",
    "Tipo Inversiones" : "Tipo Inversiones",
    "investmentTypes" : "Tipo Inversiones",
    "Clasificación Movimientos Extractos" : "Clasificación Movimientos Extractos",
    "bankTransactionClassification" : "Clasificación Movimientos Extractos",
    "Tipo Movimientos Inversiones" : "Tipo Movimientos Inversiones",
    "investmentmovementstypes" : "Tipo Movimientos Inversiones",
    "Conceptos Estimaciones" : "Conceptos Estimaciones",
    "concepts" : "Conceptos",
    "Instrumentos Inversiones" : "Instrumentos Inversiones",
    "investmentInstruments" : "Instrumentos Inversiones",
    "Estados Cheques" : "Estados Cheques",
    "chequeStates" : "Estados Cheques",
    "Estados" : "Estados",
    "states" : "Estados",
    "Formato Pagos" : "Formato de Pagos",
    "paymentMethods" : "Formato de Pagos",
    "Países" : "Países",
    "countries" : "Países",
    "Monedas" : "Monedas",
    "currencies" : "Monedas",
    "Proveedores" : "Proveedores",
    "suppliers" : "Proveedores",
    "Clientes" : "Clientes",
    "customers" : "Clientes",
    "Contactos" : "Contactos",
    "contacts" : "Contactos",
    "Tipo Cambio" : "Tipo Cambio",
    "exchangeRates" : "Tipo Cambio",
    "Tipos Cheques" : "Tipos Cheques",
    "chequeTypes" : "Tipos Cheques",
    "Áreas" : "Áreas",
    "areas" : "Áreas",
    "Bancos" : "Bancos",
    "Propios" : "Cheques Propios",
    "Egresos" : "Egresos",
    "Ingresos" : "Ingresos",
    "Gestor" : "Gestor",
    "menus" : "Menus",
    "funds" : "Fondos",
    "Fondos" : "Fondos",
    "edit menu":"Editar menu",
    "change" : "Cambiar",
    "General" : "General",
    "Casa Matriz" : "Casa Matriz",
    "headquarters" : "Casa Matriz",
    "Administración" : "Administración",
    "Administración de Menús" : "Administración de Menús",
    "Permisos" : "Permisos",
    "Usuarios" : "Usuarios",
    "Roles" : "Roles",
    "roles" : "Roles",
    "Administración de Usuarios" : "Administración de Usuarios",
    "Administración de Roles" : "Administración de Roles",
    "Conciliación Bancaria" : "Conciliación Bancaria",
    "Administrador" : "Administrador",
    "Finanzas" : "Finanzas",
    "Precios Fondos" : "Precios Fondos",
    "fundsprices" : "Precios fondos",
    "Precio Fondo exist on date" : "Ya existe un registro de precio para la fecha, instrumento de inversión y moneda seleccionada.",
    "FechaID must not be empty" : "Debe indicarse una fecha.",
    "Are you sure you want to delete this record?" : "¿Estas seguro de que deseas eliminar este registro?",
    "Revalue" : "Revaluar",
    "Revaluation done correctly" : "Revaluación hecha correctamente",
    "No records found to reevaluate" : "No se encontraron registros para revaluar",
    'NOT FOUND "Schroder Genérico" IN INVESTMENT INSTRUMENT':'No se encontró "Schroder Genérico" en Instrumentos Inversion. Debe existir este registro',
    'NOT FOUND "SUSCRIPCIÓN" IN CLASSIFICATION EXTRACTS MOVEMENTS':'No se encontró "SUSCRIPCIÓN" en Clasificación Movimientos Extractos. Debe existir este registro',
    'NOT FOUND "CREDITO POR RESCATE FCI" IN CLASSIFICATION EXTRACTS MOVEMENTS':'No se encontró "CREDITO POR RESCATE FCI" en Clasificación Movimientos Extractos. Debe existir este registro',
    'NOT FOUND "Stock y Movimientos FCI - S" IN CONCEPTS ESTIMATES':'No se encontró "Stock y Movimientos FCI - S" en Conceptos Estimaciones. Debe existir este registro',
    'NOT FOUND "Stock y Movimientos FCI - R" IN CONCEPTS ESTIMATES':'No se encontró "Stock y Movimientos FCI - R" en Conceptos Estimaciones. Debe existir este registro',

    "Inversiones LM" : "Inversiones LM",
    "SALDO INICIAL" : "Saldo Inicial",
    "Funding Income" : "Ingresos de financiación",
    "Cash Pooling Out" : "Transferencia de fondos hacia afuera",
    "Funding accounts" : "Cuentas de financiación",
    "Commissions" : "Comisiones",
    "Cash Pooling In" : "Transferencia de fondos hacia dentro",
    "Claims" : "Reclamos",
    "Tax/Bank Fees" : "Impuestos/Comisiones bancarias",
    "N/A" : "N/A",
    "Embargos Egresos" : "Embargos Egresos",
    Portapapeles: "Texto copiado al portapapeles",
    PortapapelesError: "Error al copiar el texto",
    "Operaciones Bancos": "Operaciones Bancos",
    "Tipo Movimientos Bancarios" : "Tipo Movimientos Bancarios",
    "CARTERA DE INVERSIONES" : "Cartera de inversiones",
    "success": "Exito",
    "error": "Error",
    "info": "Información",
    "warn": "Atención",
    "secondary": "Secundaria",
    "contrast": "Contraste",
  },
};

export default spanishLocale;
